<template>
  <v-dialog v-model="dialog" width="42rem">
    <v-card class="pa-6" color="whitesmoke">
      <op-alert ref="opAlert" color="red" />
      <v-skeleton-loader
        v-if="isLoading"
        type="card-avatar, list-item-three-line"
      />
      <template v-else>
        <v-container fluid class="pa-0">
          <v-row
            align="center"
            class="px-6 py-4 white mx-n6"
            no-gutters
            justify="space-around"
          >
            <v-col cols="auto">
              <img :src="accountIcon" class="account-img" />
            </v-col>
            <v-col cols="auto" class="mr-auto ml-6">
              <div
                class="text-h6 font-weight-medium steel-blue--text text-uppercase"
              >
                {{ employee?.first_name }}
              </div>
              <div
                class="text-h6 font-weight-medium steel-blue--text text-uppercase"
              >
                {{ employee?.last_name }}
              </div>
            </v-col>
            <v-spacer />
            <v-col md="auto" cols="12" class="mt-6 mt-md-0">
              <div class="grey--text text--darken-3">
                {{ $t("employees.cyberscore") }}
              </div>
              <div class="d-flex align-center mt-n2">
                <div class="d-flex align-end mr-2 green--text">
                  <div class="font-weight-bold mr-2">
                    {{ formattedCyberscore }}
                  </div>
                  <div class="font-weight-bold text-subtitle-2">
                    / 100
                  </div>
                </div>
                <op-cyberscore-gauge
                  size="small"
                  :score="cyberScore || 0"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid class="mt-6">
          <v-row no-gutters>
            <v-col
              cols="12"
              class="text-h6 font-weight-regular steel-blue--text mb-3"
            >
              Badges
            </v-col>
            <v-col cols="12">
              <v-card elevation="0" rounded="lg" class="d-flex flex-wrap">
                <op-badge
                  v-for="badge in badges"
                  :key="badge.name"
                  :type="badge.name"
                  :score="badge.score"
                />
              </v-card
              >
            </v-col>
          </v-row>
        </v-container
        >
      </template>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none rounded-lg"
          outlined
          elevation="0"
          @click="close"
        >
          {{ $t("buttons.close") }}
        </v-btn>
        <v-btn
          v-if="isSystemAdmin"
          class="text-none rounded-lg"
          outlined
          elevation="0"
          @click="toggleProfile"
        >
          {{ $t("employees.show_full_profile") }}
        </v-btn>
      </v-card-actions>
      <v-card-text v-if="profile && showDetails && isSystemAdmin" class="black--text" style="background-color: white;">
        <pre>{{ profile }}</pre>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import AlertMixin from "@/mixins/Alert.mixins.js";
import AccountIcon from "@/assets/account.png";
import CompanyLogic from "@/logics/CompanyLogic";
import AuthStore from "@/stores/AuthStore";

export default {
  name: "op-employee-cyberscore-dialog",
  mixins: [AlertMixin],
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      isSystemAdmin: false,
      isLoading: false,
      gamificationProfile: null,
      badges: null,
      profile: null,
      cyberScore: null,
      showDetails: false,
      companyUuid: null,
      employee: null,
    };
  },
  watch: {
  },
  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin");
  },
  computed: {
    accountIcon() {
      return AccountIcon;
    },
    formattedCyberscore() {
      return this.cyberScore ? Math.floor(this.cyberScore) : "--";
    },
  },
  methods: {
    open(employee, companyUuid) {
      this.companyUuid = companyUuid;
      this.employee = employee;
      this.getGamificationProfile(employee);
      this.showDetails = false;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close() {
      this.resolve(false)
      this.dialog = false
    },
    toggleProfile() {
      this.showDetails = !this.showDetails;
    },
    async getGamificationProfile(employee) {
      try {
        this.isLoading = true;
        this.badges = null;
        this.gamificationProfile = null;

        // Call the API to get gamification profile
        const profile = await CompanyLogic.getEmployeeGamificationProfile(
          this.companyUuid,
          employee.id
        );

        this.isLoading = false;

        if (profile && profile.profile && profile.profile.badges) {
          this.badges = profile.profile.badges;
          this.profile = profile.profile ?? null;
          this.gamificationProfile = profile;
          this.cyberScore = profile.profile.cyber_score ?? null;
        }
      } catch (error) {
        console.error("Error fetching gamification profile", error);
      }
    },
  },
};
</script>

<style scoped>
.green--text {
  color: #1cc8aa;
}

.account-img {
  height: 45px;
  vertical-align: middle;
}
</style>
