import CompanyLogic from "../logics/CompanyLogic"
import utilsMixin from "./utils.mixin"

export default {
  mixins: [utilsMixin],
  data: () => ({
    loading: false,
  }),
  methods: {
    getCompanyPhishingEntities(path) {
      return this._withLoading(async () => {
        const data = await CompanyLogic.getCompanyPhishingEntities(
          this.companyUuid,
          path
        )
        return data.filter((ex) => ex.archived === false)
      })
    },

    getCompanyExerciseTemplateTags(exerciseId) {
      console.log("getCompanyExerciseTemplateTags : ", exerciseId, this.companyUuid,)
      return CompanyLogic.getCompanyExerciseTemplateTagsList(
        this.companyUuid,
        exerciseId
      ).then((data) => {
        return Object.keys(data).length > 0 ? data : null;
      }).catch((error) => {
        console.error("Error fetching template tags:", error);
        return null;
      });
    },

    getCompanyExerciseCompiledWithTemplateTags(exerciseId, templateTags) {
      return this._withLoading(() =>
        CompanyLogic.getCompanyExerciseCompiledWithTemplateTags(
          this.companyUuid,
          exerciseId,
          templateTags
        )
      )
    },

    getCompanyExerciseCompiledTemplates(exerciseId) {
      return this._withLoading(async () => {
        const data = await CompanyLogic.getCompanyExerciseCompiledTemplates(
          this.companyUuid,
          exerciseId
        )
        return Object.keys(data).length > 0 ? data : null
      })
    },
  },
}
