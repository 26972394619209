<!-- SimulationForm.vue -->
<template>
  <v-expansion-panels
    v-if="localSimulation"
    class="ma-1"
    v-model="panel"
    multiple>
    <v-expansion-panel>
      <h4>{{ localSimulation.name }}</h4>

      <div class="d-flex justify-center justify-space-between">
        <v-text-field
          v-model="localSimulation.name"
          :label="$t('view.companies.simulation_name')"
          class="ma-2"
        ></v-text-field>

        <v-text-field
          :disabled="localSimulation.is_active"
          v-model="selectedExercise.title"
          filled
          :label="$t('view.companies.select_exercise')"
          readonly
          @click="showModalExercice = true"
        >
          <template #append>
            <v-icon @click="showModalExercice = true"
            >mdi-menu-down
            </v-icon>
          </template>
        </v-text-field>

        <v-dialog v-model="showModalExercice" max-width="900">
          <v-card>
            <v-card-title>{{ $t("view.companies.select_exercise") }}</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    v-for="exercise in exercises"
                    :key="exercise.id"
                    cols="4"
                  >
                    <v-card @click="selectExercise(exercise)">
                      <v-img
                        :src="
                                exercise.illustration_url
                                  ? exercise.illustration_url
                                  : require('@/assets/exercice-image.png')
                              "
                        aspect-ratio="1"
                        max-height="150"
                      ></v-img>

                      <div class="title-card">
                        {{ exercise.title }}
                      </div>
                      <v-divider class="mx-4"></v-divider>
                      <div class="subtitle">
                        <span class="label">{{ $t("view.learning_phishing.themes") }}:</span>
                        {{ exercise.theme }}
                      </div>
                      <div class="subtitle">
                        <span class="label">{{ $t("view.companies.difficulty") }}:</span>
                        {{ exercise.difficult ? exercise.level : "-" }}
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="showModalExercice = false"
              >{{ $t("buttons.close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-expansion-panel-header color="green">
        {{ $t("view.companies.more_information") }}

        <template v-slot:actions>
          <v-icon color="white">
            mdi-arrow-down-drop-circle-outline
          </v-icon>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="panel-content-border">
        <div class="d-flex justify-center justify-space-between">
          <v-text-field
            v-model="localSimulation.configuration.default_duration_days"
            :label="$t('view.companies.duration_days')"
            type="number"
            class="ma-4"
          ></v-text-field>
        </div>

        <div class="d-flex justify-center justify-space-between">
          <v-checkbox
            v-model="localSimulation.automation.automatic_launch"
            :label="$t('view.companies.automatic_launch')"
          ></v-checkbox>
          <v-checkbox
            v-model="localSimulation.automation.automatic_stop"
            :label="$t('view.companies.simulation_automatic_stop')"
          ></v-checkbox>
          <v-checkbox
            v-model="localSimulation.automation.automatic_duration_days"
            :label="$t('view.companies.automatic_duration_days')"
          ></v-checkbox>
        </div>

        <h5>{{ $t("view.companies.start_of_shipments") }} <i>{{ $t("view.companies.date_and_hours") }}</i></h5>
        <div class="d-flex justify-center justify-space-between">
          <v-date-picker
            v-model="localSimulation.configuration.launch_date"
            :label="$t('view.companies.shipment_start_date')"
            class="ma-4"
          ></v-date-picker>

          <v-time-picker
            v-model="localSimulation.configuration.launch_time"
            :label="$t('Launch time')"
            format="24hr"
            class="ma-4"
          ></v-time-picker>
        </div>

        <h5>{{ $t("view.companies.end_of_shipments") }} <i>{{ $t("view.companies.date_and_hours") }}</i></h5>
        <div class="d-flex justify-center justify-space-between">
          <v-date-picker
            v-model="localSimulation.configuration.send_by_date"
            :label="$t('view.companies.shipment_date')"
            class="ma-4"
          ></v-date-picker>

          <v-time-picker
            v-model="localSimulation.configuration.send_by_time"
            :label="$t('view.companies.shipment_hour')"
            format="24hr"
            class="ma-4"
          ></v-time-picker>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import _ from "lodash"
import companiesPhishingMixin from "@/mixins/companies.phishing.mixin";
import phishingMixin from "@/mixins/phishing.mixin";

export default {
  mixins: [
    companiesPhishingMixin,
    phishingMixin,
  ],

  name: "simulation-form",

  props: {
    value: {
      type: Object,
      required: true,
    },
    exercises: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    localSimulation: null,
    panel: [0],
    showModalExercice: false,
    selectedExercise: {},
  }),

  async mounted() {
    this.localSimulation = _.clone(this.value)
  },

  methods: {
    async initExerciseTags() {
      /**
       * Compare default exercise tags with simulation tags,
       * and use default tags if simulation tags fields are the same as the default ones
       */
      const defaultTags = await this.getCompanyExerciseTemplateTags(
        this.simulation.phishing_exercise_id
      )

      const currentTags = JSON.parse(this.simulation["template_tags"])

      if (
        defaultTags &&
        currentTags &&
        this.isTagStructureIdentical(defaultTags, currentTags)
      ) {
        this.exerciseTemplateTags = JSON.parse(JSON.stringify(currentTags))
      } else {
        this.exerciseTemplateTags = JSON.parse(JSON.stringify(defaultTags))
      }
    },

    getLocalData() {
      return this.localSimulation
    },

    selectExercise(exercise) {
      this.localSimulation.phishing_exercise_id = exercise.id
      this.selectedExercise = exercise
      this.showModalExercice = false
      this.displayTemplates(exercise.id)
    },

    async displayTemplates(exerciseId) {
      this.exerciseTemplateTags = null
      const tags = await this.getCompanyExerciseTemplateTags(exerciseId)

      this.exerciseTemplateTags = JSON.parse(JSON.stringify(tags))
    },
  },

  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    }
  },
}
</script>

<style lang="scss" scoped>
$color: #1876d2;
$color2: #000;

h4 {
  margin-bottom: 20px;
  border-bottom: $color dashed 1px;
  color: $color;
  text-transform: capitalize;

  i {
    font-size: 0.8em;
    color: #999;
  }
}

h5 {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: $color2 solid 1px;
  color: $color2;
  text-transform: capitalize;

  i {
    font-size: 0.8em;
    color: #999;
  }
}

.panel-content-border {
  border: 1px solid #ccc;
}
</style>
