<template>
  <div v-if="employee" class="main-bloc">
    <div class="info-line">
      <div class="primary-infos">
        <span class="value name">
          {{ employee.first_name }} {{ employee.last_name }}
        </span>
        <span class="value">
          {{ employee.email }}
        </span>
      </div>

      <div class="actions" v-if="addButton || deleteButton">
        <button
          v-if="deleteButton"
          @click="suppress()"
          class="removeButton"
          type="button"
        >
          {{ $t("buttons.delete") }}
        </button>

        <button v-if="addButton" @click="add()" class="addButton" type="button">
          {{ $t("buttons.select") }}
        </button>
      </div>

      <div class="secondary-infos" v-if="!partial">
        <span v-if="employee.telephone">
          <span class="desc"> {{ $t("view.companies.phone_number") }}: </span>
          <span class="value"> {{ employee.telephone }} </span>
        </span>

        <span v-if="employee.lang">
          <span class="desc"> {{ $t("view.learning_phishing.language") }}: </span>
          <span class="value"> {{ employee.lang }} </span>
        </span>

        <span v-if="employee.job">
          <span class="desc"> {{ $t("view.companies.job") }}: </span>
          <span class="value"> {{ employee.job }} </span>
        </span>

        <span v-if="employee.position">
          <span class="desc"> {{ $t("view.companies.job_position") }}: </span>
          <span class="value"> {{ employee.position }} </span>
        </span>

        <span v-if="employee.entity">
          <span class="desc"> {{ $t("view.companies.entity") }}: </span>
          <span class="value"> {{ employee.entity }} </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "oppens-employee",
  props: {
    employee: {
      default: null,
    },
    partial: {
      default: false,
    },
    addButton: {
      default: false,
    },
    deleteButton: {
      default: false,
    },
  },
  methods: {
    suppress() {
      this.$emit("suppress", this.employee)
    },
    add() {
      this.$emit("add", this.employee)
    },
  },
}
</script>
<style scoped lang="scss">
.addButton,
.removeButton {
  font-size: 0.9rem;
  border: 1px solid black;
  background-color: white;
  padding: 0 5px;
  border-radius: 4px;

  &:hover {
    background-color: #2f8bed;
    color: white;
  }
}

.removeButton {
  &:hover {
    background-color: red;
    color: white;
  }
}

.main-bloc {
  border: 1px solid #d9d9d9;
  margin: 5px;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  padding-left: 10px;

  .value {
    margin-right: 5px;
    display: inline-block;
    color: grey;
  }

  .name {
    min-width: 200px;
  }

  .desc {
    color: #000;
    font-size: 0.8rem;
  }

  .info-line {
    font-weight: normal;
    font-size: 1rem;

    .primary-infos {
      min-width: 400px;
      display: inline-block;
    }

    .secondary-infos {
      font-weight: normal;
      font-size: 0.9rem;
      float: right;
    }

    .actions {
      float: right;
    }
  }
}
</style>
