import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class PhishingRepository {



  //------------------- Generic Resources  -------------------
  static getResources(type) {
    return Request.make("GET", `v1/phishing/${type}`)
  }

  static patchOneResource(type, object) {
    return Request.make("PATCH", `v1/phishing/${type}/${object.id}`, object)
  }

  static deleteOneResource(type, id) {
    return Request.make("DELETE", `v1/phishing/${type}/${id}`)
  }

  static createOneResource(type, object) {
    return Request.make("POST", `v1/phishing/${type}`, object)
  }

  static duplicateOneResource(type, id) {
    return Request.make("POST", `v1/phishing/${type}/${id}/duplicate`)
  }


  //------------------- Generic CRUD calls -------------------

  static getAllEntities(path) {
    return Request.make("GET", `/${API_VERSION}/phishing/${path}`)
  }

  static createOneEntity(data, path) {
    return Request.make("POST", `/${API_VERSION}/phishing/${path}`, data)
  }

  static patchOneEntity(data, path) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/phishing/${path}/${data.id}`,
      data
    )
  }

  static deleteOneEntity(id, path) {
    return Request.make("DELETE", `/${API_VERSION}/phishing/${path}/${id}`)
  }

  static getOneEntity(path, id) {
    if (id) {
      return Request.make("GET", `/${API_VERSION}/phishing/${path}/${id}`)
    }
  }
  //------------------- Email Templates -------------------

  static getEmailTemplateHTMLPreview($templateId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/phishing/email-templates/${$templateId}/preview-html`
    )
  }

  //------------------- Exercises  -------------------

  static getExerciseTemplateTagsList(exerciseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/phishing/exercises/${exerciseId}/template-tags`
    )
  }

  static getExerciseCompiledTemplates(exerciseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/phishing/exercises/${exerciseId}/compiled-templates`
    )
  }

  static getExerciseCompiledWithTemplateTags(exerciseId, tags) {
    return Request.make(
      "POST",
      `/${API_VERSION}/phishing/exercises/${exerciseId}/compiled-templates-with-tags`,
      tags
    )
  }

  //------------------- Simulations  -------------------
  static getAllSimulations(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations?with_simulation_test=1&with_admin_infos=1&sorts[id]=desc`
    )
  }

  static getOneSimulation(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}?with_admin_infos=1`
    )
  }

  static cloneOneSimulation(companyUuid, simulationId) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/duplicate`
    )
  }

  static getEmployees(companyUuid, params = {}) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/employees`,
      { params }
    )
  }

  static getOneSimulationEmployees(companyUuid, simulationId, page_size, page) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/employees?page_size=${page_size}&page=${page}&simulation_ids[]=${simulationId}`
    )
  }

  static deleteOneSimulation(companyUuid, simulationId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}`
    )
  }

  static patchOneSimulation(companyUuid, simulation) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulation.id}`,
      simulation
    )
  }

  static updateOneSimulation(companyUuid, simulation) {
    return Request.make(
      "PUT",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulation.id}`,
      simulation
    )
  }

  static createOneSimulation(companyUuid, simulation) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/simulations`,
      simulation
    )
  }

  static startSimulation(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/start`
    )
  }

  static stopSimulation(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/stop`
    )
  }

  static getSimulationResults(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/results`
    )
  }

  static getSimulationCompiledTemplates(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/compiled-templates`
    )
  }

  static createSimulationTest(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/create-test`
    )
  }

  static fetchSimulationResults(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/fetch-results`
    )
  }

  static reportEmail(companyUuid, simulationId, data) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/email-reporting`,
      data
    )
  }

  static getOneSimulationTimeline(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/timeline`
    )
  }

  // ------------------- Exercices -------------------

  static archiveExercice(exerciseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/phishing/exercises/${exerciseId}/archive`
    )
  }

  // ------------------- Sending profiles -------------------

  static testSMTPSendingProfile(data) {
    return Request.make(
      "POST",
      `/${API_VERSION}/phishing/sending-profiles/test`,
      data
    )
  }

  // ------------------- Export Data -------------------
  static export(companyUuid, simulationId, type) {
    let url = ""

    url = `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/exports/${type}`

    return Request.blob("GET", url)
  }
}
