<template>
  <div>
    <v-card elevation="1" outlined color="#fff" class="ma-2" v-if="inited">
      <v-card-title>
        <v-row>
          <v-col cols="9" class="align-self-center">
            {{ localCatalog.name }}
          </v-col>
          <v-col cols="3">
            <v-chip small label dark color="#cfcfcf" class="mr-2">
              {{ localCatalog.id }}
            </v-chip>

            <v-chip v-if="localCatalog.is_public" color="orange" dark
            >{{ $t("view.learning_phishing.public") }}
            </v-chip
            >
            <v-chip v-else>{{ $t("view.learning_phishing.private") }}</v-chip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle>
        <div>{{ localCatalog.hat_courses.length }} {{ $t("view.learning_phishing.courses") }}</div>
        <div>
          <v-chip v-for="item in displayedCourses" :key="item.id" small>
            {{ item.title }}
          </v-chip>
        </div>

        <span v-if="!localCatalog.is_public">
          <div>{{ localCatalog.perimeters.length }} {{ $t("view.learning_phishing.perimeters") }}</div>
          <div>
            <v-chip v-for="item in displayedPerimeters" :key="item.id" small
            >{{ item.slug }}
            </v-chip>
          </div>

          <div>
            {{ localCatalog.all_companies.length }} {{ $t("view.learning_phishing.companies") }} ({{
              localCatalog.companies.length
            }}
            {{ $t("view.learning_phishing.direct") }})
          </div>
        </span>
      </v-card-subtitle>
      <v-card-text>
        <!-- View Mode -->
        <div v-if="!isEditMode">
          <p>{{ localCatalog.description }}</p>
        </div>

        <!-- Edit Mode -->
        <div v-else>
          <v-text-field
            v-model="localCatalog.name"
            :label="$t('view.learning_phishing.catalog_name')"
            outlined
          ></v-text-field>
          <v-textarea
            v-model="localCatalog.description"
            :label="$t('view.learning_phishing.description')"
            outlined
          ></v-textarea>
          <v-checkbox
            v-model="localCatalog.is_public"
            :label="$t('view.learning_phishing.public')"
          ></v-checkbox>

          <h3>{{ $t("view.learning_phishing.hat_courses") }}</h3>
          <v-select
            v-if="hatCourses.length"
            v-model="selectedCourses"
            :items="hatCourses"
            item-text="title"
            item-value="id"
            :label="$t('view.learning_phishing.select_courses')"
            multiple
            chips
          ></v-select>

          <h3>{{ $t("view.learning_phishing.companies_perimeters") }}</h3>
          <v-select
            v-if="perimeters.length"
            v-model="selectedPerimeters"
            :items="perimeters"
            item-text="slug"
            item-value="id"
            :label="$t('view.learning_phishing.select_perimeters')"
            multiple
            chips
          ></v-select>

          <h3>{{ $t("view.learning_phishing.companies_direct") }}</h3>
          <v-text-field
            v-model="searchQuery"
            :label="$t('view.learning_phishing.search_for_a_company')"
            outlined
          ></v-text-field>
          <div class="company-list">
            <div
              v-for="company in filteredCompanies"
              :key="company.id"
              class="company-item"
              :class="companyClass(company)"
            >
              >
              <span class="company-name mr-2">{{ company.business_name }}</span>
              <v-btn @click="toggleCompanyAccess(company)" small>{{
                  isCompanyAuthorized(company) ? "-" : "+"
                }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-icon
          @click="suppress()"
          v-if="!isEditMode"
          class="ml-2"
          elevation="2"
          icon
        >mdi-delete
        </v-icon
        >
        <v-btn
          v-if="!isEditMode"
          @click="enterEditMode"
          dark
          color="green"
          class="ml-2 text-none rounded-lg"
        >{{ $t("buttons.edit") }}
        </v-btn
        >

        <v-btn v-if="isEditMode" @click="save" dark color="green" class="text-none rounded-lg"
        >{{ $t("buttons.save") }}
        </v-btn
        >
        <v-btn v-if="isEditMode" @click="cancel" dark color="red" class="text-none rounded-lg"
        >{{ $t("buttons.cancel") }}
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import catalogMixin from "@/mixins/hatCourseCatalogs.mixin"
import {i18n} from "@/plugins/i18n";

export default {
  name: "HatCourseCatalog",

  mixins: [catalogMixin],

  props: {
    catalog: {
      type: Object,
      required: true,
    },
    hatCourses: {
      required: true,
      default: null,
    },
    companies: {
      required: true,
      default: null,
    },
    perimeters: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      isEditMode: false,
      localCatalog: {},
      selectedCourses: [],
      selectedPerimeters: [],
      inited: false,
      searchQuery: "",
    }
  },

  computed: {
    displayedCourses() {
      return this.localCatalog.hat_courses.slice(0, 100)
    },

    displayedPerimeters() {
      return this.localCatalog.perimeters.slice(0, 100)
    },

    displayedCompanies() {
      return this.localCatalog.companies.slice(0, 100)
    },

    filteredCompanies() {
      return this.companies.filter((company) =>
        company.business_name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      )
    },
  },

  mounted() {
    this.initComponent()
  },

  methods: {
    async initComponent() {
      this.localCatalog = {...this.catalog}

      this.selectedCourses = this.localCatalog.hat_courses.map((e) => e.id)

      this.selectedPerimeters = this.localCatalog.perimeters.map((e) => e.id)

      this.inited = true
    },

    enterEditMode() {
      this.isEditMode = true
    },

    async save() {
      const payload = {
        ...this.localCatalog,
        course_ids: this.selectedCourses,
        perimeters_ids: this.selectedPerimeters,
        company_uuids: this.localCatalog.companies.map((c) => c.uuid),
      }

      try {
        await this.updateCatalog(payload.id, payload) // Assuming you have an updateCatalog method that handles the API call
        this.isEditMode = false
        this.$emit("update")
      } catch (error) {
        console.error("An error occurred while updating the catalog:", error)
      }
    },

    async suppress() {
      await this.deleteCatalog(this.localCatalog.id)
      this.$emit("deleted")
    },

    isCompanyAuthorized(company) {
      return !!this.localCatalog.companies.find(
        (c) => c.uuid === company.uuid
      )
    },
    toggleCompanyAccess(company) {
      const index = this.localCatalog.companies.findIndex(
        (c) => c.uuid === company.uuid
      )

      if (index > -1) {
        // retourne nouveau tableau pour forcer la réactivité de la filtered list
        this.localCatalog.companies = [
          ...this.localCatalog.companies.slice(0, index),
          ...this.localCatalog.companies.slice(index + 1),
        ]
      } else {
        this.localCatalog.companies = [...this.localCatalog.companies, company]
      }
    },

    cancel() {
      this.isEditMode = false
    },

    companyClass(company) {
      return this.isCompanyAuthorized(company) ? i18n.t('authorized') : ""
    },
  },
}
</script>

<style scoped>
.company-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.company-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.authorized {
  background-color: #e0e0e0;
}

.company-name {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
