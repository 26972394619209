<template>
  <div v-if="localData">
    <v-card elevation="0" outlined color="white" class="ma-1">
      <v-card-text>
        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.description") }}</h3>
          <v-text-field
            :label="$t('view.learning_phishing.description')"
            v-model="localData.description"
            class="required"
          >
          </v-text-field>
        </v-row>
        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.score") }}</h3>
          <v-text-field
            :label="$t('view.learning_phishing.score')"
            v-model="localData.score"
            hide-details
            single-line
            type="number"
          />
        </v-row>
        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.languages") }}</h3>
          <v-select
            :label="$t('view.learning_phishing.select_a_language')"
            v-model="localData.lang"
            hide-details
            single-line
            :items="languages"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg"
        >{{ $t("buttons.save") }}
        </v-btn
        >
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{ $t("buttons.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import oppensLearningMixin from "@/mixins/oppens.learning.mixin.js"

export default {
  mixins: [oppensLearningMixin],

  name: "oppens-learning-answer-edition",

  props: {
    data: {
      default: null,
    },

    languages: {
      default: null,
    },
  },

  data: function () {
    return {
      localData: null,
    }
  },

  async mounted() {
    this.localData = JSON.parse(JSON.stringify(this.data))
  },

  computed: {
    form() {
      return this.$refs.form
    },
  },

  methods: {
    async submit() {
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>
