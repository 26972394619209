import LearningRepository from "../repositories/LearningRepository"
import PhishingRepository from "@/repositories/PhishingRepository"

export default class LearningLogic {
  static async getAvailableHatCourses(companyUuid) {
    const response = await LearningRepository.getAvailableHatCourses(
      companyUuid,
    )
    let list = response.responseObject()
    return list.data
  }

  static async getAllCourses(companyUuid) {
    const response = await LearningRepository.getAllCourses(companyUuid)
    let list = response.responseObject()
    return list.data.company_courses
  }

  static async pushCourse(companyUuid, course) {
    let response
    if (course.id) {
      response = await LearningRepository.patchOneCourse(companyUuid, course)
    } else {
      response = await LearningRepository.createOneCourse(companyUuid, course)
    }

    let obj = response.responseObject()
    if (obj.data) {
      return obj.data
    }
    return obj
  }

  static async deleteCourse(companyUuid, courseId) {
    const response = await LearningRepository.deleteOneCourse(
      companyUuid,
      courseId,
    )
    return response.responseObject()
  }

  static async startCourse(companyUuid, courseId) {
    const response = await LearningRepository.startOneCourse(
      companyUuid,
      courseId,
    )
    return response.responseObject()
  }

  static async stopCourse(companyUuid, courseId) {
    const response = await LearningRepository.stopOneCourse(
      companyUuid,
      courseId,
    )
    return response.responseObject()
  }

  static async getCourseResults(companyUuid, courseId) {
    const response = await LearningRepository.getOneCourseResults(
      companyUuid,
      courseId,
    )
    return response.responseObject().data
  }

  /**
   *
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static async getEmployees(companyUuid, params = {}) {
    const res = await LearningRepository.getEmployees(companyUuid, params)
    return res?.response?.data
  }

  static async getOneCourse(companyUuid, courseId) {
    const response = await LearningRepository.getOneCourse(
      companyUuid,
      courseId,
    )

    return response.responseObject().data
  }

  static async inviteUsersToCourse(companyUuid, courseId) {
    const response = await LearningRepository.inviteUsersToCourse(
      companyUuid,
      courseId,
    )
    return response.responseObject().data
  }

  static async remindUsersOfCourse(companyUuid, courseId) {
    const response = await LearningRepository.remindUsersOfCourse(
      companyUuid,
      courseId,
    )

    return response.responseObject().data
  }

  static async getOppensLearningResources(type) {
    const response = await LearningRepository.getResources(type)
    return response.responseObject().data
  }

  static async deleteOppensLearningResources(type, data) {
    const response = await LearningRepository.deleteOneResource(
      type,
      data.id,
    )

    return response.responseObject().data
  }


  static async duplicateResource(type, data) {
    const response = await LearningRepository.duplicateOneResource(
      type,
      data.id
    )
    return response.responseObject().data
  }

  static async pushResource(type, object) {
    let response
    if (object.id) {
      response = await LearningRepository.patchOneResource(
        type,
        object,
      )
    } else {
      response = await LearningRepository.createOneResource(
        type,
        object,
      )
    }

    let list = response.responseObject()
    return list.data
  }

  static async duplicateCourse(courseId) {
    const response = await LearningRepository.duplicateCourseWithSlides(
      courseId,
    )
    return response.responseObject().data
  }

  static async courseDownloadScorm(courseId, data) {
    return await LearningRepository.courseDownloadScorm(courseId, data)
  }

  static async moveSlides(object) {
    const response = await LearningRepository.moveSlides(object)
    return response.responseObject().data
  }


  //-------------------- export data -------------------
  static async export(companyUuid, companyCourseId, type) {
    try {
      const res = await LearningRepository.export(
        companyUuid,
        companyCourseId,
        type,
      )
      return res
    } catch (e) {
      return e.message
    }
  }
}
