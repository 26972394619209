import Request from "../services/Request"

export default class UserRepository {


  static patchOne(userUuid, body = {}) {
    return Request.make("PATCH", `/users/${userUuid}`, body)
  }
  static getAllNonAnonymousUsers(params) {
    return Request.make("GET", "/admin/users/all", { params })
  }

  static async getPaginatedUsers({ page, per_page, sortBy = [], sortDesc = [], search = '' }) {
    let params = {
      page,
      per_page,
      search,
    }

    if (sortBy.length > 0) {
      params.sort_by = sortBy[0]
      params.sort_desc = sortDesc[0]
    }

    return Request.make("GET", `/admin/users`, { params })
  }

  static getUserRoles() {
    return Request.make("GET", "/user-roles")
  }

  static getNotifications(unread) {
    const url = unread ? "/v1/notifications?unread=true" : "/v1/notifications";
    return Request.make("GET", url)
  }

  static markNotificationAsRead(notificationId) {
    return Request.make("POST", `/v1/notifications/${notificationId}/read`)
  }

  static markAllAsRead() {
    return Request.make("POST", `/v1/notifications/read-all`)
  }
}
