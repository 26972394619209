<!-- ProgramForm.vue -->
<template>
  <v-card v-if="isDataLoaded">
    <div class="ma-2 pa-5">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <div class="d-flex justify-center justify-space-between">
            <v-text-field
              v-model="localProgram.name"
              :label="$t('view.companies.program_name')"
              class="ma-4"
            ></v-text-field>

            <v-text-field
              class="ma-4"
              v-model="localProgram.description"
              :label="$t('view.companies.program_description')"
            ></v-text-field>
          </div>

          <v-expansion-panel-header color="green">
            {{ $t("view.companies.more_information") }}

            <template v-slot:actions>
              <v-icon color="white">
                mdi-arrow-down-drop-circle-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="panel-content-border">
            <!-- date de début et de fin du programme -->
            <v-row>
              <v-col cols="6" lg="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="localProgram.configuration.start_date"
                      :label="$t('view.dashboard.start_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="localProgram.configuration.start_date"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" lg="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="localProgram.configuration.end_date"
                      :label="$t('view.companies.end_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="localProgram.configuration.end_date"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- nb de simulation-results par group -->
            <v-row>
              <v-col cols="6">
                <v-select
                  v-if="groups"
                  v-model="localProgram.configuration.employee_groups_ids"
                  :items="groups"
                  item-value="id"
                  item-text="name"
                  :label="$t('view.companies.employees_group')"
                  multiple
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  v-model="localProgram.configuration.one_simulation_per_group"
                  :label="$t('view.companies.one_simulation_per_group')"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-card-text>
      <div v-for="(campaign, index) in localCampaigns" :key="index">
        <campaign-form
          ref="campaignForms"
          :value="campaign"
          :hatCourses="hatCourses"
          :emailTypes="emailTypes"
          :exercises="exercises"
          @update="(val) => $set(localCampaigns, index, val)"
        ></campaign-form>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash"
import moment from "moment"
import CampaignForm from "./CampaignForm.vue"
import CompanyLogic from "@/logics/CompanyLogic"

export default {
  name: "ProgramForm",
  components: {
    CampaignForm,
  },
  props: ["value", "hatCourses", "emailTypes"],
  data: () => ({
    localProgram: null,
    localCampaigns: null,
    loaded: false,
    menu1: false,
    menu2: false,
    panel: [0],
    exercises: [],
    groups: [],
  }),
  async mounted() {

    this.groups = await  CompanyLogic.getAllEmployeeGroups(this.companyUuid)


    const program = _.clone(this.value)


    if(this.exercises.length == 0){
      console.log("call 1")
      this.exercises = await CompanyLogic.getCompanyPhishingEntities(this.companyUuid, "exercises")
    }
    this.initProgramStart(program)

    let campaigns = _.clone(this.value.campaigns)
    this.initCampaignsStart(program, campaigns)

    this.localProgram = program
    this.localCampaigns = campaigns
  },
  methods: {
    initCampaignsStart(program, campaigns) {
      const programStartMoment = moment(program.configuration.start_date)
      for (let i = 0; i < campaigns.length; i++) {
        campaigns[i].configuration.start_date = programStartMoment
          .add(campaigns[i].configuration.start_days_from_program_start, "days")
          .format("YYYY-MM-DD")
      }
    },
    initProgramStart(program) {
      program.configuration.start_date = moment().format("YYYY-MM-DD")
      program.configuration.end_date = moment()
        .add(program.configuration.default_duration_days, "days")
        .format("YYYY-MM-DD")
    },
    updateCampaignsStart() {
      const campaignForms = this.$refs.campaignForms

      if (!campaignForms) {
        console.error("No campaign forms found")
        return
      }

      for (let i = 0; i < campaignForms.length; i++) {
        campaignForms[i].updateStartDateFromProgramStart(
          this.localProgram.configuration.start_date
        )
      }
    },
    getLocalData() {
      this.localProgram.campaigns = []

      const campaignsForms = this.$refs.campaignForms
      if (!campaignsForms) {
        console.error("No campaign forms found")
        return
      }

      for (let i = 0; i < campaignsForms.length; i++) {
        this.localProgram.campaigns.push(campaignsForms[i].getLocalData())
      }

      return this.localProgram
    },
  },
  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
    isDataLoaded() {
      return (
        this.localProgram &&
        this.localCampaigns &&
        this.groups &&
        this.hatCourses &&
        this.emailTypes &&
        this.exercises
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-content-border {
  border: 1px solid #ccc;
}
</style>
