export default {
  data() {
    return {
      exerciceToTagsMap: [],
      exerciseTags: null, // formely exerciseTags
    }
  },
  methods: {

    async initExerciseTags(simulation) {

      if (!simulation.phishing_exercise_id) return

      const defaultTags = await this.getDefaultTags(simulation.phishing_exercise_id)

      const unparsedTags = simulation["template_tags"]
      if (!unparsedTags) {
        this.exerciseTags = JSON.parse(JSON.stringify(defaultTags))
        return
      }

      const currentTags = JSON.parse(simulation["template_tags"])

      if (defaultTags && currentTags) {
        this.exerciseTags = this.mergeTags(defaultTags, currentTags)
      } else {
        this.exerciseTags = JSON.parse(JSON.stringify(defaultTags))
      }
    },
    /*
    Pour toutes les catégories de tags (email, landingPage, etc.), fusionne les tags par défaut avec les tags personnalisés.
    Si une catégorie est absente dans les tags personnalisés, copie simplement la catégorie par défaut.
     */
    mergeTags(defaultTags, customTags) {

      const mergedTags = JSON.parse(JSON.stringify(customTags))

      for (const category of Object.keys(defaultTags)) {
        if (!mergedTags[category]) {
          mergedTags[category] = JSON.parse(JSON.stringify(defaultTags[category]))
        } else {
          for (const [key, value] of Object.entries(defaultTags[category].tags)) {
            if (!mergedTags[category].tags[key]) {
              mergedTags[category].tags[key] = value
            }
          }
        }
      }

      return mergedTags
    },

    async getDefaultTags(phishingExerciseId) {

      if (!this.exerciceToTagsMap[phishingExerciseId]) {
        this.exerciceToTagsMap[phishingExerciseId] = []
      }

      if (!this.exerciceToTagsMap[phishingExerciseId]["defaultTags"]) {
        const defaultTags = await this.getCompanyExerciseTemplateTags(
          phishingExerciseId,
        )
        this.exerciceToTagsMap[phishingExerciseId]["defaultTags"] = defaultTags

      }
      return this.exerciceToTagsMap[phishingExerciseId]["defaultTags"]
    },
  },
}

