<template>
  <v-card
    v-bind="$attrs"
    v-on="$listeners"
    :loading="loading ? loadingColor : false"
    class="card"
  >
    <div style="width: 200px; margin: 0 auto; margin-bottom: 8%; padding: 5px">
      <v-img
        alt="Logo"
        :src="require('../../assets/oppens-logo-white.png')"
        width="99%"
      >
      </v-img>
    </div>



    <op-form ref="form" v-if="mustSend2faCode">
      <v-card-text class="px-3 pa-0">
        <op-text-field
          name="code"
          type="text"
          background-color="white"
          rules="required"
          v-model="code"
          :label="
            $t(
              useRecoveryCode
                ? 'profile.doubleAuth.recoveryCodeLabel'
                : 'profile.doubleAuth.codeLabel'
            )
          "
          autofocus
          @keyup.enter.native="submitCode()"
        >
        </op-text-field>
      </v-card-text>
      <v-card-text class="px-3 pa-0">
        <v-checkbox
          name="useRecoveryCode"
          v-model="useRecoveryCode"
          :label="$t('profile.doubleAuth.useRecoveryCodeLabel')"
        >
        </v-checkbox>
      </v-card-text>
      <v-card-text class="px-3 pa-0 text-center">
        <v-btn
          block
          large
          dark
          :color="submitBtnColor"
          :loading="loading"
          class="text-none"
          @click="submitCode"
        >
          {{ $t("label.validate") }}
        </v-btn>
      </v-card-text>
    </op-form>

    <op-form ref="form" v-else-if="!mustSend2faCode && !hasForgottenPassword">
      <v-card-text class="px-3 pa-0">
        <op-text-field
          name="email"
          type="email"
          background-color="white"
          rules="required|email"
          v-model="email"
          :label="$t('label.email')"
          autofocus
        >
        </op-text-field>
      </v-card-text>

      <v-card-text class="px-3 pa-0">
        <op-text-field
          name="password"
          rules="required"
          background-color="white"
          v-model="password"
          :label="$t('label.password')"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @keyup.enter.native="submit()"
        >
        </op-text-field>
      </v-card-text>

      <v-card-text class="px-3 pa-0 text-center">
        <v-btn
          block
          large
          dark
          :color="submitBtnColor"
          :loading="loading"
          class="text-none"
          @click="submit"
        >
          {{ $t("label.connexion") }}
        </v-btn>
      </v-card-text>

      <v-card-text class="px-3 pa-0 mt-2 text-center">
        <v-btn
          text
          :color="submitBtnColor"
          @click="hasForgottenPassword = true"
          class="text-none"
        >
          {{ $t("label.forgot_password") }}
        </v-btn>
      </v-card-text>
    </op-form>

    <op-form ref="form" v-else-if="hasForgottenPassword">
      <v-card-title>Modifier mon mot de passe</v-card-title>
      <v-card-text class="px-3 pa-0">
        <op-text-field
          name="email"
          type="email"
          background-color="white"
          rules="required|email"
          v-model="email"
          :label="$t('label.email')"
        >
        </op-text-field>
      </v-card-text>
      <v-card-text class="px-3 pa-0 text-center">
        <v-btn
          block
          large
          dark
          :color="submitBtnColor"
          :loading="loading"
          class="text-none"
          @click="forgotPassword"
        >
          {{ $t("label.send") }}
        </v-btn>
      </v-card-text>
      <v-card-text class="px-3 pa-0 mt-2 text-center">
        <v-btn
          text
          :color="submitBtnColor"
          @click="hasForgottenPassword = false"
          class="text-none"
        >
          {{ $t("label.connexionScreen") }}
        </v-btn>
      </v-card-text>
    </op-form>
  </v-card>
</template>

<script>
import AuthLogic from "@/logics/AuthLogic"
import DoubleAuthLogic from "@/logics/DoubleAuthLogic"

export default {
  name: "op-login-card",

  components: {},

  data: () => ({
    email: "",
    password: "",
    showPassword: false,
    code: "",
    useRecoveryCode: false,
    loading: false,
    mustSend2faCode: false,
    hasForgottenPassword: false,
  }),

  props: {
    loadingColor: {
      type: String,
      default: "green",
    },
    submitBtnColor: {
      type: String,
      default: "green",
    },
  },

  computed: {
    form() {
      return this.$refs.form
    },
  },

  methods: {
    redirectAfterLogin() {
      this.$router.push({ name: "dashboard" })
    },
    async login(body) {
      this.loading = true

      let user = this.$authStore.me

      AuthLogic.login(body)
        .then((res) => {
          this.loading = false
          if (res.mustSend2faCode === true) {
            this.mustSend2faCode = true
          } else {
            this.redirectAfterLogin()
          }
        })
        .catch((error) => {
          this.loading = false
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.displayError(error.response)
          } else if (error.response && 429 === error.response.status) {
            error.response = {
              data: {
                message: this.$i18n.t("login.errors.429-too-many-attempts"),
              },
            }
          } else {
            error.response = {
              data: {
                message: this.$i18n.t("login.errors.401-unauthorized"),
              },
            }
          }
          this.displayError(error.response)
        })
    },
    async submit() {
      const isValid = await this.form.validate()

      if (isValid) {
        await this.login({
          email: this.email,
          username: this.email,
          password: this.password,
        })
      }
    },
    async forgotPassword() {
      if (!this.email) {
        const errorMessage = {
          data: {
            error: "no email",
            message: this.$i18n.t("forgot-password.no-email"),
          },
        }
        this.displayError(errorMessage)
        return
      }

      AuthLogic.forgotPassword(this.email)
        .then((result) => {
          this.displaySuccess(result.message)
        })
        .catch((error) => {
          if (error.response && 429 === error.response.status) {
            error.response = {
              data: {
                message: this.$i18n.t(
                  "forgot-password.errors.429-too-many-attempts"
                ),
              },
            }
          } else {
            error.response = {
              data: {
                message: this.$i18n.t(
                  "forgot-password.errors.401-unauthorized"
                ),
              },
            }
          }

          this.displayError(error.response)
        })
    },
    async submitCode() {
      this.loading = true
      try {
        if (this.useRecoveryCode) {
          await DoubleAuthLogic.postRecoveryCode(this.code)
        } else {
          await DoubleAuthLogic.postChallenge(this.code)
        }
        this.redirectAfterLogin()
      } catch (error) {
        this.displayError(error.response)
      } finally {
        this.loading = false
      }
    },
    displayError(response) {
      this.form.displayError(response)
    },

    displaySuccess(str) {
      if(!str || str === "") {
       return;
      }
      this.form.displaySuccess(str)
    },
  },
}
</script>
