<template>
  <v-container class="pa-0">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>

    <op-modale
      :display="show"
      :data="html"
      v-on:closed="closedPreview"
    ></op-modale>

    <v-card elevation="0" outlined color="white">
      <v-card-text v-if="localCourse && hatCourses">
        <v-row>
          <!-- nom de la formation -->
          <v-col cols="6">
            <v-text-field
              :label="$t('view.learning_phishing.learning_name')"
              v-model="localCourse.name"
            >
            </v-text-field>
          </v-col>

          <!-- description de la formation -->
          <v-col cols="6">
            <v-text-field
              :label="$t('view.learning_phishing.description')"
              v-model="localCourse.description"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <!-- sélection de la formation de base (hat course) -->
          <v-col cols="6">
            <v-select
              v-model="selectedHatCourse"
              :items="hatCourses"
              item-text="title"
              return-object
              filled
              readonly
              :label="$t('view.learning_phishing.learning_selection')"
              class="required pt-2 pb-2"
              :disabled="localCourse.is_active"
              @click.native="openModal"
            ></v-select>
          </v-col>

          <v-dialog v-model="showModalCours" max-width="80%">
            <v-card>
              <v-card-title>
                <div style="width: 100%; display: inline-block">
                  {{ $t("view.learning_phishing.select_course") }}
                </div>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('view.companies.search')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="availableLangArray"
                      v-model="selectedLanguage"
                      item-text="label"
                      item-value="code"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <div v-if="noCoursesFound" class="text-center my-4">
                    {{ $t("view.companies.no_courses_available") }}
                  </div>
                  <div v-else class="d-flex flex-wrap justify-space-around">
                    <div
                      v-for="course in filteredCourses"
                      :key="course.id"
                      class="ma-1"
                      style="width: 23em"
                    >
                      <v-card min-width="250" @click="selectCourse(course)">
                        <v-img
                          :src="
                            course.image
                              ? course.image
                              : require('@/assets/exercice-image.png')
                          "
                          aspect-ratio="1"
                          max-height="150"
                        ></v-img>

                        <div class="title-card">
                          {{ course.title }}
                        </div>
                        <v-divider class="mx-4"></v-divider>
                        <div class="subtitle">
                          <span class="label"
                          >{{
                              $t("view.learning_phishing.description")
                            }}:</span
                          >
                          {{ truncateDescription(course.description) }}
                        </div>
                        <div class="subtitle">
                          <span class="label"
                          >{{ $t("view.learning_phishing.duration") }}:
                          </span>
                          {{ course.duration }}
                        </div>

                        <div class="subtitle">
                          <div
                            style="
                              display: inline-block;
                              position: relative;
                              z-index: 9999;
                            "
                          >
                            <div
                              v-for="lang in getLanguages(course.courses)"
                              :key="lang"
                              style="display: inline-block; margin-right: 4px"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <div v-on="on">
                                    <Language-flag-component
                                      :name="lang"
                                    ></Language-flag-component>
                                  </div>
                                </template>
                                <span style="z-index: 9999">{{
                                    getLanguageName(lang)
                                  }}</span>
                              </v-tooltip>
                            </div>
                            <div
                              v-if="excessLanguages(course.courses) > 0"
                              style="display: inline-block; margin-left: 4px"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <div v-on="on">
                                    +{{ excessLanguages(course.courses) }}
                                    {{ $t("More") }}
                                  </div>
                                </template>
                                <span style="z-index: 9999">
                                  {{
                                    getExcessLanguageNames(course.courses).join(
                                      ", ",
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="showModalCours = false"
                >Fermer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-col cols="6">
            <v-select
              v-model="selectedEmployeeGroups"
              :items="employeeGroups"
              multiple
              chips
              item-text="name"
              filled
              :label="$t('view.companies.employees_group_selection')"
              return-object
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedCampaign"
              :items="campaignsWithNull"
              item-text="name"
              filled
              :label="$t('view.companies.campaign_selection')"
              return-object
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-select
              v-model="localCourse.invitation_email_type"
              :items="invitationEmailTypes"
              item-text="name"
              item-value="type"
              filled
              :label="$t('view.companies.email_type_invitation')"
              @change="onSelectInvitation"
            ></v-select>
          </v-col>
          <v-col
            cols="1"
            class="d-flex justify-center"
            style="
              flex-direction: column-reverse;
              padding: 0;
              padding-right: 12px;
            "
          >
            <op-preview-button
              v-model="selectedInvitationLang"
              :options="invitationSelect"
              @update="onInvitationEmailChange($event)"
            />
          </v-col>

          <v-col cols="5">
            <v-select
              v-model="localCourse.reminder_email_type"
              :items="reminderEmailTypes"
              item-text="name"
              item-value="type"
              filled
              @change="onSelectReminder"
              :label="$t('view.companies.type_of_reminder_email')"
            ></v-select>
          </v-col>
          <v-col
            cols="1"
            class="d-flex justify-center"
            style="
              flex-direction: column-reverse;
              padding: 0;
              padding-right: 12px;
            "
          >
            <op-preview-button
              v-model="selectedReminderLang"
              :options="reminderSelect"
              @update="onReminderEmailChange($event)"
            />
          </v-col>
        </v-row>
        <v-row v-if="customizationMessageInvitation || customizationMessageReminder">
          <v-col cols="6">
            <v-alert v-if="customizationMessageInvitation" type="info" dense color="#FFA726">
              {{ customizationMessageInvitation }}
            </v-alert>
          </v-col>
          <v-col cols="6">
            <v-alert v-if="customizationMessageReminder" type="info" dense color="#FFA726">
              {{ customizationMessageReminder }}
            </v-alert>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="6">
            <div class="flex-item">
              <v-switch
                v-model="localCourse.is_mandatory"
                :label="$t('view.companies.mandatory_learning')"
                color="green"
              ></v-switch>
            </div>
          </v-col>
        </v-row>

        <div style="font-size: 18px; padding: 0 8px" class="mt-8">
          <v-icon class="mr-2">mdi-settings</v-icon>
          {{ $t("view.companies.automation") }}
        </div>

        <v-divider class="mt-2"></v-divider>

        <v-row class="mt-4 ms-6 me-6">
          <v-col cols="12" sm="6" md="4" lg="3" v-if="isSystemAdmin">
            <v-switch
              v-model="localCourse.is_live"
              :label="$t('view.companies.mode_live')"
              color="green"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_invites"
              :label="$t('view.companies.automatic_invitation')"
              color="green"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_launch"
              :label="$t('view.companies.automatic_launch')"
              v-on:change="resetDate"
              :disabled="localCourse.is_active"
              color="green"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <op-datepicker
              :label="$t('view.companies.launch_date')"
              :date="localCourse.start_date"
              :minDate="tomorrowString"
              v-on:update="updateStartDate"
              :readonly="
                !localCourse.has_automatic_launch || localCourse.is_active
              "
              :required="localCourse.has_automatic_launch"
              :style="{
                pointerEvents: localCourse.has_automatic_launch
                  ? 'auto'
                  : 'none',
              }"
            >
            </op-datepicker>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_reminders"
              :label="$t('view.companies.automatic_reminders')"
              color="green"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_report"
              :label="$t('view.companies.automatic_report')"
              color="green"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_stop"
              :label="$t('view.companies.automatic_stop')"
              @change="resetDate"
              color="green"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <op-datepicker
              :label="'Date d\'arrêt'"
              :date="calculateStopDate(localCourse.start_date)"
              readonly
              :style="{
                pointerEvents: 'none',
              }"
            ></op-datepicker>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_employee_group_refresh"
              color="green"
              :label="$t('view.companies.automatic_employee_group_refresh')"
            >
              <template v-slot:label>
                <div class="d-flex align-center">
                  <span>{{
                      $t("view.companies.automatic_employee_group_refresh")
                    }}</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        color="grey"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("view.companies.hint_daily_update") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-switch>
          </v-col>

          <!--v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_employee_group_refresh"
              :label="$t('view.companies.automatic_employee_group_refresh')"
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  color="primary"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>{{ $t('view.companies.hint_daily_update') }}</span>
            </v-tooltip>
            </v-switch>
          </v-col-->
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg"
        >{{ $t("buttons.save") }}
        </v-btn>
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{
            $t("buttons.close")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-container>
</template>

<script>
import { cloneDeep } from "lodash"
import AuthStore from "@/stores/AuthStore"
import programsMixin from "@/mixins/programs.mixin"
import employeesMixin from "@/mixins/employees.mixin"
import learningMixin from "@/mixins/learning.mixin"
import oppensLearningMixin from "@/mixins/oppens.learning.mixin.js"
import campaignsMixin from "@/mixins/campaigns.mixin"
import emailsMixin from "@/mixins/emails.mixin"
import { i18n } from "@/plugins/i18n"
import LocaleLogic from "../../../logics/LocaleLogic"
import { showSnackbar, unAuthorized } from "@/services/GlobalActions"

export default {
  mixins: [
    programsMixin,
    employeesMixin,
    learningMixin,
    oppensLearningMixin,
    campaignsMixin,
    emailsMixin,
  ],
  name: "learning-company-course-edition",
  props: {
    course: {
      default: null,
    },
    campaignId: {
      default: null,
    },
  },
  data: function() {
    return {
      isSystemAdmin: false,
      localCourse: null,
      selectedProgram: null,
      selectedCampaign: null,
      selectedEmployeeGroups: null,
      selectedHatCourse: null,
      campaignsWithNull: null,
      availableEmailTypes: null,
      invitationEmailTypes: null,
      reminderEmailTypes: null,
      programs: null,
      campaigns: null,
      hatCourses: [],
      invitation_email: null,
      reminder_email: null,
      show: false,
      html: null,
      reminder_index: null,
      reminderSelect: [],
      invitationSelect: [],
      selectedInvitationLang: "",
      selectedReminderLang: "",
      showModalCours: false,
      search: "",
      selectedLanguage: "",
      langArray: [
        { code: "", label: i18n.t("view.learning_phishing.all_languages") },
        { code: "de", label: "Deutsch" },
        { code: "nl", label: "Dutch" },
        { code: "en", label: "English" },
        { code: "es", label: "Español" },
        { code: "fr", label: "Français" },
        { code: "it", label: "Italiano" },
        { code: "pl", label: "Polska" },
        { code: "pt", label: "Português" },
        { code: "ro", label: "Română" },
        { code: "sv", label: "Svenska" },
        { code: "tr", label: "Türkçe" },
      ],
      maxLanguages: 5,
      customizationMessageInvitation: "",
      customizationMessageReminder: "",
      tomorrow: new Date().toISOString().substr(0, 10),
    }
  },
  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin")

    //this.programs = await this.getAllPrograms()
    this.campaigns = await this.getAllCampaigns()

    if (this.campaignId) {
      this.selectedCampaign = this.campaigns.find(
        (c) => c.id === this.campaignId,
      )
      /*if (this.selectedCampaign) {
        this.selectedProgram = this.programs.find(
          (p) => p.id === this.selectedCampaign.program_id
        )
      }*/
    }

    this.campaignsWithNull = [].concat(this.campaigns) // add a null campaign to the list

    await this.getAndSortEmailTypes()

    this.hatCourses = await this.getAvailableHatCourses()

    this.employeeGroups = await this.getAllEmployeeGroups()

    this.localCourse = cloneDeep(this.course)

    this.reminder_index = this.availableEmailTypes.findIndex(
      (et) => et.type === this.localCourse.reminder_email_type,
    )
    this.reminderSelect = this.availableEmailTypes[this.reminder_index].langs
    this.findInvitationEmailIndex()
    this.invitationSelect =
      this.availableEmailTypes[this.invitation_index].langs
    this.selectedInvitationLang = this.invitationSelect.includes("fr")
      ? "fr"
      : this.invitationSelect[0]
    this.selectedReminderLang = this.reminderSelect.includes("fr")
      ? "fr"
      : this.reminderSelect[0]

    this.initProgramAndGroups()
    this.checkCustomization()

    this.$emit("loaded")
  },

  computed: {
    tomorrowString() {
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1) // Add one day
      return tomorrow.toISOString().substr(0, 10) // Format as YYYY-MM-DD
    },
    companyUuid() {
      return this.$route.params.companyUuid
    },
    form() {
      return this.$refs.form
    },
    // Filter by description or title and selected language, then sorts them alphabetically by title.
    filteredCourses() {
      return (
        this.hatCourses
          .filter((hatCourse) => {
            // Check if the course title or description contains the search string (case insensitive)
            const matchesSearch =
              hatCourse.title.toLowerCase().includes(this.search.toLowerCase()) ||
              (hatCourse.description &&
                hatCourse.description
                  .toLowerCase()
                  .includes(this.search.toLowerCase()))


            console.log("hatCourse", hatCourse)

            // Check if any of the courses languages match the selected language
            const courseLanguages = this.getLanguages(hatCourse.courses, false)
            const matchesLanguage =
              this.selectedLanguage === ""
                ? true
                : courseLanguages.includes(this.selectedLanguage)

            // Return true if both conditions are met
            return matchesSearch && matchesLanguage
          })
          // Sort the filtered courses alphabetically by their title
          .sort((a, b) => a.title.localeCompare(b.title))
      )
    },
    // To display a message to the user when the search returns no results
    noCoursesFound() {
      return this.filteredCourses.length === 0
    },
    // Get all available languages from the courses
    availableLangArray() {
      const availableLangs = new Set()
      this.hatCourses.forEach((course) => {
        course.courses.forEach((subCourse) => {
          availableLangs.add(subCourse.lang)
        })
      })
      // Filter `langArray` to keep only the available languages
      return this.langArray.filter(
        (lang) => availableLangs.has(lang.code) || lang.code === "",
      )
    },
  },
  methods: {
    async getAndSortEmailTypes(lang) {

      const availableEmailTypes = await this.getAvailableEmailTypes(
        this.getLocale(LocaleLogic.getBrowserLanguage()),
      )

      this.availableEmailTypes = availableEmailTypes
      this.invitationEmailTypes = availableEmailTypes.filter(
        (et) => et.type.includes("invitation"),
      )
      this.reminderEmailTypes = availableEmailTypes.filter(
        (et) => et.type.includes("reminder"),
      )
    },
    openModal() {
      this.showModalCours = true
    },
    getLocale(locale) {
      if (LocaleLogic.availableLocales().includes(locale)) {
        return locale
      } else {
        return "en"
      }
    },
    truncateDescription(description) {
      const words = description.split(" ")
      if (words.length > 4) {
        return words.slice(0, 4).join(" ") + "..."
      }
      return description
    },
    calculateStopDate(date) {
      const startDateObj = new Date(date)

      const stopDate = new Date(
        startDateObj.getTime() + 60 * 24 * 60 * 60 * 1000,
      )
      if (this.localCourse.has_automatic_stop) {
        return stopDate.toISOString().split("T")[0]
      } else {
        return ""
      }
    },
    selectCourse(course) {
      if (course && course.id) {
        this.localCourse.hat_course_id = course.id
        this.selectedHatCourse = course
      }
      this.showModalCours = false
    },

    // Vérifie la customisation pour l'invitation email type et le reminder email type
    checkCustomization() {
      const selectedInvitationEmailType = this.availableEmailTypes.find(
        (et) => et.type === this.localCourse.invitation_email_type,
      )
      if (selectedInvitationEmailType && selectedInvitationEmailType.customization) {
        this.customizationMessageInvitation = i18n.t("view.companies.customization_message")
      }

      const selectedReminderEmailType = this.availableEmailTypes.find(
        (et) => et.type === this.localCourse.reminder_email_type,
      )
      if (selectedReminderEmailType && selectedReminderEmailType.customization) {
        this.customizationMessageReminder = i18n.t("view.companies.customization_message")
      }
    },


    async onSelectInvitation() {
      this.findInvitationEmailIndex()
      const selectedEmailType = this.availableEmailTypes[this.invitation_index]
      this.invitationSelect = selectedEmailType.langs
      this.customizationMessageInvitation = selectedEmailType.customization
        ? i18n.t("view.companies.customization_message")
        : ""
    },
    async onSelectReminder() {
      this.findReminderEmailIndex()
      const selectedEmailType = this.availableEmailTypes[this.reminder_index]
      this.reminderSelect = selectedEmailType.langs
      this.customizationMessageReminder = selectedEmailType.customization
        ? i18n.t("view.companies.customization_message")
        : ""
    },
    async onInvitationEmailChange(value) {
      if (!this.selectedHatCourse) {
        this.results = null
        showSnackbar(i18n.t("view.snackbar.select_hatcourse"))
        return
      }
      this.selectedInvitationLang = this.invitationSelect.includes(value)
        ? value
        : this.invitationSelect[0]
      await this.getInvitationEmail()
      this.html = `<div>${this.invitation_email.content}</div>`
      this.show = true
    },
    async onReminderEmailChange(value) {
      if (!this.selectedHatCourse) {
        this.results = null
        showSnackbar(i18n.t("view.snackbar.select_hatcourse"))
        return
      }
      this.selectedReminderLang = this.reminderSelect.includes(value)
        ? value
        : this.reminderSelect[0]
      await this.getReminderEmail()
      this.html = `<div>${this.reminder_email.content}</div>`
      this.show = true
    },
    closedPreview() {
      this.html = null
      this.show = false
    },
    async getInvitationEmail() {
      this.invitation_email = await this.getEmailTypes(
        this.localCourse.invitation_email_type,
        this.selectedInvitationLang,
        this.selectedHatCourse.id,
      )
    },
    async getReminderEmail() {
      this.reminder_email = await this.getEmailTypes(
        this.localCourse.reminder_email_type,
        this.selectedReminderLang,
        this.selectedHatCourse.id,
      )
    },
    findInvitationEmailIndex() {
      this.invitation_index = this.availableEmailTypes.findIndex(
        (et) => et.type === this.localCourse.invitation_email_type,
      )
    },
    findReminderEmailIndex() {
      this.reminder_index = this.availableEmailTypes.findIndex(
        (et) => et.type === this.localCourse.reminder_email_type,
      )
    },
    initProgramAndGroups() {
      if (
        this.course &&
        this.course.employee_groups &&
        this.course.employee_groups.length > 0
      ) {
        // init selected  employee groups
        const selectEmployeeGroupsIds = this.course.employee_groups.map(
          (g) => g.id,
        )
        this.selectedEmployeeGroups = this.employeeGroups.filter((group) =>
          selectEmployeeGroupsIds.includes(group.id),
        )
      }

      if (this.localCourse.hat_course) {
        this.selectedHatCourse = this.hatCourses.find(
          (p) => p.id === this.localCourse.hat_course.id,
        )
      }

      if (this.localCourse.program) {
        this.selectedProgram = this.programs.find(
          (p) => p.id === this.localCourse.program.id,
        )
      }

      if (this.localCourse.campaign) {
        this.selectedCampaign = this.campaigns.find(
          (p) => p.id === this.localCourse.campaign.id,
        )
      }
    },

    async submit() {
      if (!this.selectedCampaign || !this.selectedCampaign.id) {
        console.error(
          "need to select a campaign",
          this.selectedCampaign
        )
        return
      }

      if (this.selectedHatCourse) {
        this.localCourse.hat_course_id = this.selectedHatCourse.id
      }
      
      if (this.selectedCampaign && this.selectedCampaign.id) {
        this.localCourse.program_id = this.selectedCampaign.program_id
        this.localCourse.campaign_id = this.selectedCampaign.id
      } else {
        this.localCourse.campaign_id = null
      }

      if (!this.localCourse.hat_course_id) {
        this.results = null
        showSnackbar(i18n.t("view.snackbar.select_hatcourse"))
        return
      }

      if (
        this.localCourse.has_automatic_launch &&
        this.localCourse.start_date === null
      ) {
        console.error("need to select start date")
        //return
      }

      this.localCourse.employee_groups_ids =
        this.selectedEmployeeGroups && this.selectedEmployeeGroups.length > 0
          ? this.selectedEmployeeGroups.map(($group) => $group.id)
          : []

      this.$emit("save", this.localCourse)
    },
    resetDate() {
      if (!this.localCourse.has_automatic_launch) {
        this.localCourse.start_date = null
        this.localCourse.has_automatic_stop = false
      }
    },
    updateStartDate(data) {
      this.localCourse.start_date = data
    },
    close() {
      this.$emit("close")
    },

    // Extracts and returns a list of languages from courses limited to the first `maxLanguages` languages.
    getLanguages(courses, limit = true) {

      if (!courses) return []

      const languages = courses.map((course) => course.lang)
      const uniqueLanguages = [...new Set(languages)]
      return limit
        ? uniqueLanguages.slice(0, this.maxLanguages)
        : uniqueLanguages
    },

    getLanguageName(code) {
      const language = this.langArray.find((lang) => lang.code === code)
      return language ? language.label : code
    },
    // Calculates the number of excess languages beyond `maxLanguages`.
    /*
    excessLanguages(courses) {
      const languages = courses.map((course) => course.lang)
      const uniqueLanguages = [...new Set(languages)]
      return uniqueLanguages.length - this.maxLanguages
    },
    getExcessLanguageNames(courses) {
      const languages = courses.map((course) => course.lang)
      const uniqueLanguages = [...new Set(languages)]
      return uniqueLanguages.slice(this.maxLanguages).map(this.getLanguageName)
    },*/
    excessLanguages(courses) {
      // Ensure `courses` is defined and is an array
      if (!Array.isArray(courses)) {
        console.warn("Invalid input to excessLanguages, expected an array:", courses)
        return 0
      }

      const languages = courses
        .map((course) => course?.lang) // Safely access `lang`
        .filter(Boolean) // Remove undefined or null values

      const uniqueLanguages = [...new Set(languages)]

      return Math.max(0, uniqueLanguages.length - this.maxLanguages) // Prevent negative results
    },

    getExcessLanguageNames(courses) {
      // Ensure `courses` is defined and is an array
      if (!Array.isArray(courses)) {
        console.warn("Invalid input to getExcessLanguageNames, expected an array:", courses)
        return []
      }

      const languages = courses
        .map((course) => course?.lang) // Safely access `lang`
        .filter(Boolean) // Remove undefined or null values

      const uniqueLanguages = [...new Set(languages)]

      return uniqueLanguages
        .slice(this.maxLanguages) // Extract only the excess languages
        .map(this.getLanguageName) // Map language codes to names
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap; /* Allows the items to wrap onto the next line */
  justify-content: space-between;
}

.flex-item {
  flex: 1 1 auto; /* Adjust this to control how the items grow and shrink */
  margin: 5px; /* Adds some space between the items */
}

.title-card {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 10px 5px;
}

.subtitle {
  font-size: 14px;
  color: #757575;
  margin: 0 0 0 5px;
}
</style>
