<template>
  <div v-if="data">
    <v-card elevation="0" outlined class="ma-1">
      <v-card-title class="pl-0 ps-4">
        <!--v-tooltip slot="append" top>
          <template v-slot:activator="{ on }">
            <v-icon :color="getStatus(status).color" v-on="on" class="me-2">
              {{ getStatus(status).icon }}
            </v-icon>
          </template>
          <span>Status: {{ status }}</span>
        </v-tooltip-->

        {{ data.url }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("view.learning_phishing.server_ip_adress") }} :
        {{ data.ip_adress }}</v-card-subtitle
      >

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" class="text-none rounded-lg" @click="edit()">{{
          $t("buttons.edit")
        }}</v-btn>
        <v-icon @click="suppress()" class="ml-2" elevation="2" icon
          >mdi-delete
        </v-icon>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import monitoringMixins from "@/mixins/monitoring.mixins.js"
export default {
  mixins: [monitoringMixins],
  name: "phishing-server",

  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      monitoringRecord: null,
      status: null,
    }
  },

  async mounted() {
    /*if (this.data && this.data.id) {
      this.monitoringRecord = await this.getServerStatus(this.data.id)
      if (this.monitoringRecord) {
        this.status = this.monitoringRecord.status
      }
    }*/
  },

  methods: {
    getStatus(status) {
      const statusMap = {
        up: { icon: "mdi-check-circle", color: "green" },
        down: {
          icon: "mdi-close-circle",
          color: "red",
        },
        unknown: { icon: "mdi-alert-circle-outline", color: "grey darken-3" },
      }

      return statusMap[status] || statusMap.unknown
    },
    async suppress() {
      this.$emit("suppress", this.data)
    },
    edit() {
      this.$emit("edit", this.data)
    },
  },
}
</script>
