import AuthLogic from "@/logics/AuthLogic"
import Vue from "vue"

class AuthStore {
  constructor() {
    this.me = null
    this.token = null
    this.state = Vue.observable({
      me: null,
      token: null,
      roles: null,
      isRefreshingRoles: false,
    })
    this.saveAnonymous = false
    
  }

  setRefreshingRoles(isRefreshingRoles) {
    this.state.isRefreshingRoles = isRefreshingRoles
  }

  isRefreshingRoles() {
    return this.state.isRefreshingRoles
  }

  setToken(token) {
    this.token = token
    this.state.token = token
  }

  setMe(user) {
    this.state.me = user
    this.me = user
     // Store is_saas in localStorage
     const isSaas = user.company.is_saas;
     localStorage.setItem('is_saas', isSaas);
   }

   getMyTimezone() {
    return this.me.timezone
   }

   isUserMemberOfSaasCompany() {
    return this.me.company.is_saas;
   }

  getMyCompany() {
    return this.me.company ?? null
  }

  setPermissions(permissions) {
    this.state.permissions = permissions
  }  
  setRoles(roles) {
    this.state.roles = roles
  }

  getRoles() {
    return this.state.roles
  }

  reset() {
    this.me = null
    this.token = null
    this.state.me = null
    this.state.token = null
  }

  isSignedIn() {
    return this.state.me && !this.state.me.getAttribute("is_anonymous")
  }

  isAnonymous() {
    return this.state.me && this.state.me.getAttribute("is_anonymous")
  }

  /** @description called by App.vue if we have a token */
  async sync() {

    if (!AuthLogic.me()) {
      await AuthLogic.loadMe()
    }

    // get values from localstorage ?
    this.me = AuthLogic.me()
    this.token = AuthLogic.getTokens()
    this.state.me = AuthLogic.me()
    this.state.token = AuthLogic.getTokens()
    await AuthLogic.refreshRoles()

    /* Prevents refreshing of token and doesn't seem useful */
    /* await AuthLogic.refreshPermissions() */
  }

  /*
   * returns true if the user has the system role
   */
  async hasSystemRole(role) {

    let roles = this.getRoles()

    if (!roles) {
      roles = await AuthLogic.refreshRoles()
    }
    if (!roles["system-roles"]) {
      return false
    }
    return roles["system-roles"].includes(role)
  }

  /*
   * returns true if the user has the company role in the given company
   */
  async hasCompanyRole(role, companyUuid) {
    let roles = this.getRoles()
    if (!roles) {
      roles = await AuthLogic.refreshRoles()
    }

    if (!roles[role]) {
      return false
    }

    return roles[role].includes(companyUuid)
  }
}

export default new AuthStore()
