import AdminLogic from "../logics/AdminLogic"
import CompanyLogic from "../logics/CompanyLogic"
import utilsMixin from "./utils.mixin"

export default {
  mixins: [utilsMixin],
  data: () => ({
    users: null,
  }),
  methods: {

    getCompanyUsers(companyUuid, selectedRoles = null) {
      return this._withLoading(() =>
        CompanyLogic.getCompanyUsers(companyUuid, selectedRoles)
      )
    },

    getEmployeeHierarchy(companyUuid, userUuid) {
      return this._withLoading(() =>
        CompanyLogic.getEmployeeHierarchy(companyUuid, userUuid)
      )
    },

    getUserCompanyRoles(companyUuid, userUuid) {
      return this._withLoading(() =>
        CompanyLogic.getUserCompanyRoles(companyUuid, userUuid)
      )
    },

    getUserPerimeters(user, roleSlug = null) {
      return this._withLoading(() =>
        //AdminLogic.getUserPerimeters(user.uuid, roleSlug)
        AdminLogic.getUserPerimeters(user.uuid, roleSlug).then((response) => {
          // Ensure the returned value is always an array
          return response && typeof response === "object" ? Object.values(response) : [];
        })
      )
    },
  },
}
