<script>
import { i18n } from "@/plugins/i18n"
import companiesMixin from "@/mixins/companies.mixins"

export default {
  name: "company-domains-edition",
  mixins: [companiesMixin],
  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      localData: null,
      dialogButton: true,
      companyUuid: null,
      domains: [],
      domainName: {
        name: "",
      },
      domainHeaders: [
        { text: i18n.t("view.companies.name"), value: "name" },
        { text: i18n.t("view.companies.verified_at"), value: "verified_at" },
        {
          text: i18n.t("view.companies.verification_method"),
          value: "verification_method",
        },
        { text: i18n.t("view.companies.token"), value: "token" },
        { text: i18n.t("view.companies.status"), value: "is_verified" },
        { text: i18n.t("view.companies.actions"), value: "actions" },
      ],
    }
  },
  async mounted() {

    this.localData = JSON.parse(JSON.stringify(this.data))
    this.companyUuid = this.localData.uuid

    await this.refreshDomains();
    // Sorting domains in alphabetical order by name
    this.domains.sort((a, b) => a.name.localeCompare(b.name))
  },
  watch: {
  },
  methods: {
    // Domains
    async refreshDomains() {
      this.domains = []
      if (this.companyUuid) {
        this.domains = await this.getDomains(this.companyUuid)
      }

      this.domainName = { ...this.domainName, name: "" }
    },

    async addDomain() {
      await this.saveDomain(this.data.uuid, this.domainName)
      await this.refreshDomains(this.data)
    },

    async confirmDeleteDomain(domain) {
      const str1 = i18n.t("view.companies.confirm_delete");
      const str2 = i18n.t("view.companies.confirm_delete_domain_message");
      const str3 = i18n.t("Cancel");

      if (
        await this.$refs.confirm.open(
          str1,
          str2,
          str3
        )
      ) {
        await this.deleteDomain(this.data.uuid, domain.id)
        await this.refreshDomains(this.data)
      }
    },

    async editDomain(domain) {
      const body = { name: domain.name }
      await this.updateDomain(this.data.uuid, body, domain.id)
      await this.refreshDomains(this.data)
    },

    async verifyDomain(domain) {

      const response = await this.checkDomain(this.companyUuid, domain.id)
      this.dialogButton = false

      if (!response) {
        const str1 = i18n.t("view.companies.verification_failed");
        const str2 = i18n.t("view.companies.add_DNS_token_message")+'<br>"'+`<strong>${domain.token}</strong>`+'"';
        const str3 = i18n.t("buttons.close");

        await this.$refs.confirm.open(
          str1,
          str2,
          str3
        )
        this.dialogButton = true
      } else {
        const str1 = i18n.t("view.companies.verification_passed");
        const str2 = i18n.t("view.companies.congratulation_domain_verified");
        const str3 = i18n.t("buttons.close");

        await this.$refs.confirm.open(
          str1,
          str2,
          str3
        )
        this.dialogButton = true
      }

      await this.refreshDomains(this.data)
    },

    async validate() {
      return true
    },
    getCompanyData() {
      return {
        domains: this.domains,
      }
    },
  },
}
</script>

<template>
  <v-container fluid style="width: 100%; height: 100%" :style="{ backgroundColor: '#ffffff' }">
    
    <op-confirmation-dialog ref="confirm" :showButton="dialogButton" />

    <div v-if="localData" class="d-block">
      <div class="bloc-large">
        <v-row>
          <v-col cols="12">
            <div class="bloc-title">
              {{ $t("view.companies.your_domain_names") }}
            </div>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="9">
            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="domainName"
                color="green"
                :label="$t('view.companies.your_domain_names')"
                v-model="domainName.name"
              >
              </op-text-field>
            </v-card-text>
          </v-col>

          <v-col cols="1">
            <v-btn elevation="2" @click="addDomain" dark color="green" class="text-none rounded-lg mr-3"
              >{{ $t("buttons.add") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :headers="domainHeaders"
          :items="domains"
          :sort-by="['name']"
          :sort-desc="false"
          class="elevation-1 mb-5"
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.name"
              large
              @save="editDomain(item)"
            >
              <div class="d-flex justify-space-between" style="width: 100%">
                <span>{{ item.name }}</span>
                <v-icon small class="ml-2">mdi-pencil</v-icon>
              </div>

              <template v-slot:input>
                <v-text-field
                  v-model="item.name"
                  label="Edit"
                  single-line
                  full-width
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.is_verified`]="{ item }">
            <v-icon v-if="item.is_verified" color="green"
              >mdi-check-circle
            </v-icon>

            <v-icon v-else color="red">mdi-close-circle</v-icon>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <span class="px-2">
              <v-icon small @click="verifyDomain(item)">mdi-sync</v-icon>
            </span>

            <span class="px-2">
              <v-icon small @click="confirmDeleteDomain(item)">mdi-delete</v-icon>
            </span>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.bloc-large {
  border: 1px solid #efefef;
  border-radius: 5px;
  flex: 1 1 auto;
  padding: 20px;

}
  .bloc-title {
    font-weight: bold;
    text-align: center;
    font-size: 1.5em;
    color: #2a2929;
  }
</style>
