<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    persistent
    class="no-border-radius"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-h4  font-weight-bold black--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4 black--text"
      ><span v-html="message"></span
      ></v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noconfirm"
          color="grey"
          text
          class="body-2 font-weight-bold text-none rounded-lg"
          @click.native="cancel"
          name="buttonName"
        >{{ buttonName }}
        </v-btn
        >
        <v-btn
          color="green"
          class="body-2 font-weight-bold text-none rounded-lg"
          outlined
          @click.native="agree"
          v-if="showButton"
        >{{ $t("buttons.confirm") }}
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "op-confirmation-dialog",
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      buttonName: null,
      options: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    }
  },

  methods: {
    open(title, message, buttonName, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.buttonName = buttonName
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>
