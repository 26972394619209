import Vue from "vue"
import axios from "axios"
import AuthLogic from "@/logics/AuthLogic"
import JsonApiResponse from "./JsonApiResponse"

import { showSnackbar, unAuthorized } from "./GlobalActions"

class Request {
  constructor() {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_API_HOST,
      headers: {
        Accept: "application/json",
        //"Content-Type": "application/json",
      },
    })

    this.client.interceptors.request.use((config) => {
      const tokens = AuthLogic.getTokens()

      if (tokens) {
        config.headers["Authorization"] = `Bearer ${tokens.access_token}`
      }

      if (!config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/json"; // Default Content-Type
      }

      return Promise.resolve(config)
    })
  }

  /**
   * Make a new AJAX request.
   *
   * @param {string} method
   * @param {string} url
   * @param {Object} config
   * @returns {Promise}
   */
  async make(method, url, config = {}) {

    try {
      let response = await this.client[method.toLowerCase()](url, config)
      const data = new JsonApiResponse(response)
      let object = data.responseObject()
      if (object && object.message) {
        showSnackbar(object.message, "#1AC8AA")
      }
      return data
    } catch (error) {
      return await this.parseError(error, url)
    }
  }

  /**
   * Dedicated method for file uploads
   *
   * @param {string} url - API endpoint
   * @param {FormData} formData - The form data containing files and additional payload
   * @param {Object} config - Additional Axios config (optional)
   * @returns {Promise}
   */
  async upload(url, formData, config = {}) {
    try {
      // Log the payload for debugging
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      // Make the Axios POST request
      let response = await this.client.post(url, formData, {
        ...config,
        headers: {
          "Content-Type": "multipart/form-data", // Ensure multipart header
          ...config.headers,
        },
      });

      // Process the response with JsonApiResponse
      const data = new JsonApiResponse(response);
      let object = data.responseObject();
      if (object && object.message) {
        showSnackbar(object.message, "#1AC8AA"); // Show snackbar if message exists
      }

      return data;
    } catch (error) {
      console.error("File upload failed:", error);
      return await this.parseError(error, url);
    }
  }


  /**
   * Get blob from AJAX request (to download files).
   *
   * @param {string} method
   * @param {string} url
   * @param {Object} config
   * @returns {Promise}
   */
  async blob(method, url, config = {}) {
    try {
      let response
      if (method.toLocaleLowerCase() === "post") {
        response = await this.client.post(url, config, {
          responseType: "blob",
        })
      } else {
        response = await this.client[method.toLowerCase()](url, {
          ...config,
          responseType: "blob",
        })
      }
      return response
    } catch (error) {
      return await this.parseError(error, url)
    }
  }

  /**
   * Check error response and launch custom action
   *
   * @param error
   * @returns {Promise<void>}
   */
  async parseError(error, url) {
    if (error?.response) {
      if (401 === error.response.status && "/login" != url) {
        const accessToken = AuthLogic.getTokens()

        if (accessToken?.refresh_token) {
          try {
            await AuthLogic.refreshToken(accessToken.refresh_token)
            let response = await this.client.request(error.config)
            return new JsonApiResponse(response)
          } catch (error) {
            AuthLogic.removeMe()
            AuthLogic.removeTokens()

            unAuthorized("401-unauthorized")
            // EventBus.$emit("401-unauthorized");
            throw error
          }
        } else {
          AuthLogic.removeMe()
          AuthLogic.removeTokens()

          unAuthorized("401-unauthorized")
          // EventBus.$emit("401-unauthorized");
          throw error
        }
      } else {
        if (error.response.data) {
          let errorMsg = " "

          // parsing du nouveau format d'erreur renvoyé par l'API
          if (error.response.data.error) {
            errorMsg = error.response.data.error.message

            if (error.response.data.error.code) {
              errorMsg += " (" + error.response.data.error.code + ")"
            }
          } else {
            const data = error.response.data
            const _errors = data.errors

            if (data.message) {
              errorMsg += data.message
            } else if (data.error) {
              errorMsg += data.error
            }

            if (
              typeof _errors === "object" &&
              !Array.isArray(_errors) &&
              _errors !== null
            ) {
              Object.values(_errors).forEach((item) => {
                errorMsg += " " + item.toString()
              })
            }
          }

          showSnackbar(errorMsg, "#FF5252")
        }
        throw error
      }
    }
  }
}

export default new Request()
