import CompanyLogic from "@/logics/CompanyLogic"
import AdminLogic from "@/logics/AdminLogic"
import utilsMixin from "./utils.mixin"
import { log } from "async"

export default {
  mixins: [utilsMixin],
  methods: {
    getAllCompaniesNoPagination() {
      return this._withLoading(() => CompanyLogic.getAllCompaniesNoPagination())
    },

    saveCompany(data) {
      return this._withLoading(() => CompanyLogic.saveCompany(data))
    },

    suppressCompany(data) {
      return this._withLoading(() => CompanyLogic.deleteCompany(data))
    },

    notifyCompanyUsers(companyUuid, body) {
      return this._withLoading(() =>
        CompanyLogic.notifyCompanyUsers(companyUuid, body)
      )
    },

    getCompanyLogs() {
      return this._withLoading(() =>
        AdminLogic.getCompanyLogs(this.companyUuid, true)
      )
    },

    reComputeStoppedStats() {
      return this._withLoading(() =>
        CompanyLogic.reComputeStoppedStats(this.companyUuid)
      )
    },

    refreshGamification() {
      return this._withLoading(() =>
        CompanyLogic.refreshGamification(this.companyUuid)
      )
    },

    saveCompanyUser(companyUuid, user) {
      return this._withLoading(() =>
        CompanyLogic.saveCompanyUser(companyUuid, user)
      )
    },

    //-------------- Domains ---------------//

    saveDomain(companyUuid, domainName) {
      return this._withLoading(() =>
        CompanyLogic.saveDomain(companyUuid, domainName)
      )
    },

    updateDomain(companyUuid, domainName, domainId) {
      return this._withLoading(() =>
        CompanyLogic.saveDomain(companyUuid, domainName, domainId)
      )
    },

    getDomains(companyUuid) {
      return this._withLoading(() => CompanyLogic.getAllDomains(companyUuid))
    },

    deleteDomain(companyUuid, domainId) {
      return this._withLoading(() =>
        CompanyLogic.deleteDomain(companyUuid, domainId)
      )
    },

    checkDomain(companyUuid, domainId) {
      return this._withLoading(() =>
        CompanyLogic.checkDomain(companyUuid, domainId)
      )
    },
    //
    getNewCompanyObject() {
      return {
        business_name: "",
        trade_name: "",
        siren_number: "",
        tva_number: "",
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          postal_code: null,
        },
        city: "",
        postcode: "",
        phone_number: "",
        email_address: "",
        website: "",
        field_of_activity: "",
        headcount: 0,
        desktop_computer_count: 0,
        laptops_count: 0,
        mobile_phone_count: 0,
        is_self_editing_website: false,
        sharing_documents_frequency: "",
        uses: [],
        employees_auth_method: "magic_link",
      }
    },
  },
}
