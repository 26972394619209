<script>
import ConfigurationTab from "./MainConfig.vue"
import EmailTab from "./EmailConfig.vue"
import LandingPageTab from "./LandingPageConfig.vue"
import ErrorPageTab from "./ErrorPageConfig.vue"

//TODO virer les mixin inutiles
import programsMixin from "@/mixins/programs.mixin"
import phishingMixin from "@/mixins/phishing.mixin"
import employeesMixin from "@/mixins/employees.mixin"
import campaignsMixin from "@/mixins/campaigns.mixin"
import companiesPhishingMixin from "@/mixins/companies.phishing.mixin"
import { i18n } from "@/plugins/i18n"
import monitoringMixins from "@/mixins/monitoring.mixins.js"
import AuthStore from "@/stores/AuthStore"
import simulationTagsMixin from "@/mixins/simulation.tags.mixin"
import moment from "moment-timezone"
import { showSnackbar, unAuthorized } from "@/services/GlobalActions"


export default {
  name: "phishing-simulation-edition",
  components: {
    ConfigurationTab,
    EmailTab,
    LandingPageTab,
    ErrorPageTab,
  },
  mixins: [
    programsMixin,
    companiesPhishingMixin,
    employeesMixin,
    campaignsMixin,
    phishingMixin,
    monitoringMixins,
    simulationTagsMixin,
  ],
  props: {
    simulation: {
      default: null,
    },
    campaignId: {
      default: null,
    },
  },
  data() {
    return {
      selectedTab: 0,
      localSimulation: null,
      previousSimulationState: null,
      campaigns: null, // should be a prop
      exercises: null, // should be a prop
      sendingProfiles: [], // should be a prop
      employeeGroups: [], // should be a prop
      isSystemAdmin: false,
      loaded: false,
      exerciseTags: null,
      exerciseTemplates: null,
      dialogOpened: false,
    }
  },
  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin")
    this.campaigns = await this.getAllCampaigns()
    //this.exercises =  await this.getCompanyPhishingEntities("exercises");
    this.employeeGroups = await this.getAllEmployeeGroups()


    this.getCompanyPhishingEntities("exercises").then((exercises) => {
      this.exercises = exercises;
    }).catch((error) => {
      console.error("Failed to load exercises:", error);
    });


    const simulation = JSON.parse(JSON.stringify(this.simulation))

    // Set the employee group ids !! important for the select
    simulation.employee_group_ids = simulation.employee_groups?.map(group => group.id) || []

    this.validateCampaignId(simulation)
    this.updateSimulationCampaignFromCampaignId(simulation)

    this.localSimulation = simulation
    this.loaded = true
    this.dialogOpened = true
  },
  watch: {
    localSimulation: {
      async handler(newValue) {
        if (
          !this.previousSimulationState ||
          this.previousSimulationState.phishing_exercise_id !== newValue.phishing_exercise_id) {


          if(this.previousSimulationState &&
            this.previousSimulationState.phishing_exercise_id !== newValue.phishing_exercise_id){

            // Reset the exercise tags if the exercise has changed
            this.exerciseTags = null
            newValue["template_tags"] = null
          }

          await this.initExerciseTags(newValue)
        }

        this.previousSimulationState = newValue
      },
      deep: true,
    },
  },
  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
    areTabsEnabled() {
      const hasSimulation = !!this.localSimulation
      const hasExerciseId = hasSimulation && !!this.localSimulation.phishing_exercise_id
      const isActiveOrStopped = this.localSimulation?.is_active || this.localSimulation?.is_stopped
      return hasSimulation && hasExerciseId && !isActiveOrStopped
    },
  },
  methods: {
    validateCampaignId(simulation) {
      if (!simulation.campaign_id) return

      const validCampaign = this.campaigns.some(
        (campaign) => campaign.id === simulation.campaign_id,
      )

      if (!validCampaign) {
        simulation.campaign_id = null  // Reset if invalid
      }
    },
    updateSimulationCampaignFromCampaignId(simulation) {
      if (this.campaignId) {
        const campaign = this.campaigns.find((c) => c.id === this.campaignId)
        simulation.campaign_id = campaign ? campaign.id : null
      }
    },
    updateSimulation(data) {
      this.localSimulation = { ...this.localSimulation, ...data }
    },
    close() {
      this.dialogOpened = false
      this.$emit("close")
    },
    displayErrorMsg(msg) {
      showSnackbar(msg)
    },
    calculateMinutesUntilLaunch() {
      if (!this.localSimulation.launch_date || !this.localSimulation.launch_time || !this.localSimulation.timezone) {
        return null
      }

      // Combine la date et l'heure de lancement
      const launchDateTimeString = `${this.localSimulation.launch_date} ${this.localSimulation.launch_time}`
      // Parse la date et l'heure en utilisant la timezone de la simulation
      const launchMoment = moment.tz(launchDateTimeString, "YYYY-MM-DD HH:mm", this.localSimulation.timezone)

      // Moment actuel en UTC
      const now = moment.utc()

      // Convertir le lancement en UTC pour comparaison
      const launchInUTC = launchMoment.clone().utc()

      // Calculer la différence en minutes
      const diffMinutes = launchInUTC.diff(now, "minutes")

      return diffMinutes
    },

    async handleAutomaticLaunchConfirmation() {
      const minutesUntilLaunch = this.calculateMinutesUntilLaunch()

      if (minutesUntilLaunch === null) {
        showSnackbar(this.$t("view.companies.invalid_launch_time"))
        return false
      }

      if (minutesUntilLaunch < 0) {
        const confirmed = await this.$refs.confirm.open(
          this.$t("view.companies.launch_time_passed"),
          this.$t("view.companies.launch_time_passed_message"),
          this.$t("view.companies.cancel_launch"),
        )
        return confirmed
      } else if (minutesUntilLaunch < 60) {
        const confirmed = await this.$refs.confirm.open(
          this.$t("view.companies.launch_soon"),
          this.$t("view.companies.launch_soon_message"),
          this.$t("view.companies.cancel_launch"),
        )
        return confirmed
      }

      return true
    },

    async save() {

      if (
        (!this.localSimulation.is_active && !this.localSimulation.is_stopped) &&(
          !this.localSimulation.employee_group_ids ||
          this.localSimulation.employee_group_ids.length <= 0
        )) {
        showSnackbar(i18n.t("view.companies.select_employee_group"))
        return
      }

      if(!this.localSimulation.campaign_id){
        showSnackbar(i18n.t("view.companies.select_campaign"))
        return
      }


      if(!this.localSimulation.name || !this.localSimulation.name.trim()){
        showSnackbar(i18n.t("view.companies.define_simulation_name"))
        return
      }

      if(!this.localSimulation.phishing_exercise_id){
        showSnackbar(i18n.t("view.companies.select_phishing_exercise"))
        return
      }

      // Ensure `has_automatic_launch` and `has_automatic_stop` are properly set
      if (!this.localSimulation.has_automatic_launch) {
        this.localSimulation.has_automatic_launch = false
      }
      if (!this.localSimulation.has_automatic_stop) {
        this.localSimulation.has_automatic_stop = false
      }


      // Si `has_automatic_launch` est activé, gérer la confirmation
      if (this.localSimulation.has_automatic_launch) {
        const confirmed = await this.handleAutomaticLaunchConfirmation()
        if (!confirmed) {
          return
        }
      }

      this.saveSimulation()
    },

    async saveSimulation() {
      // Retrieve updated data from child components
      const emailConfig = this.$refs.emailConfig?.getUpdatedData()
      const landingPageConfig = await this.$refs.landingPageConfig?.getUpdatedData()
      const errorPageConfig = await this.$refs.errorPageConfig?.getUpdatedData()


      // check the errorPageConfig url format
      if (errorPageConfig && errorPageConfig.error) {
        showSnackbar(errorPageConfig.errorMessage)
        return
      }


      // build template tags from the two subcomponents
      let templateTags = null
      if (this.exerciseTags) {
        templateTags = this.exerciseTags
        if (emailConfig && emailConfig.exerciseTags) {
          templateTags["email"] = emailConfig.exerciseTags.email
        }
        if (landingPageConfig && landingPageConfig.exerciseTags) {
          templateTags["landingPage"] = landingPageConfig.exerciseTags.landingPage
        }
      }

      const updatedSimulation = {
        ...this.localSimulation,
        phishing_custom_email: emailConfig?.phishing_custom_email ?? this.localSimulation.phishing_custom_email,
        phishing_custom_sending_profile: emailConfig?.phishing_custom_sending_profile ?? this.localSimulation.phishing_custom_sending_profile,
        phishing_custom_landing_page: landingPageConfig?.phishing_custom_landing_page ?? this.localSimulation.phishing_custom_landing_page,
        phishing_custom_error_page: errorPageConfig?.phishing_custom_error_page ?? this.localSimulation.phishing_custom_error_page,
        template_tags: templateTags,
      }

      if (emailConfig) {
        updatedSimulation.has_phishing_custom_email = emailConfig.has_phishing_custom_email
        updatedSimulation.has_phishing_custom_sending_profile = emailConfig.has_phishing_custom_sending_profile
      }
      if (landingPageConfig) {
        updatedSimulation.has_phishing_custom_landing_page = landingPageConfig.has_phishing_custom_landing_page
      }
      if (errorPageConfig) {
        updatedSimulation.has_phishing_custom_error_page = errorPageConfig.has_phishing_custom_error_page
      }

      this.dialogOpened = false
      this.$emit("save", updatedSimulation)
    },
  },
}
</script>
<template>
  <v-dialog v-model="dialogOpened" fullscreen eager @keydown.esc="close">
    <v-card class="pa-12">
      <v-card-title class="d-flex align-center justify-space-between px-12">
        <span>Edition/Création</span>
        <div>
          <v-btn icon @click="save" color="green">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon @click="close" color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-container fluid class="px-12">
        <v-tabs color="primary" v-model="selectedTab">
          <v-tab>General</v-tab>
          <v-tab :disabled="!areTabsEnabled">Email</v-tab>
          <v-tab :disabled="!areTabsEnabled">Landing Page</v-tab>
          <v-tab :disabled="!areTabsEnabled">Error Page</v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <configuration-tab
              v-model="localSimulation"
              :campaigns="campaigns"
              :employeeGroups="employeeGroups"
              :exercises="exercises"
              :sendingProfiles="sendingProfiles"
              :isSystemAdmin="isSystemAdmin"
              :exerciseTags="exerciseTags"
              @update-simulation="updateSimulation"
            />
          </v-tab-item>
          <v-tab-item>
            <email-tab
              ref="emailConfig"
              :simulation="localSimulation"
              :exerciseTags="exerciseTags"
              @error="displayErrorMsg"
            />
          </v-tab-item>
          <v-tab-item>
            <landing-page-tab
              ref="landingPageConfig"
              :simulation="localSimulation"
              :exerciseTags="exerciseTags"
              @error="displayErrorMsg"
            />
          </v-tab-item>
          <v-tab-item>
            <error-page-tab
              ref="errorPageConfig"
              :simulation="localSimulation"
            />
          </v-tab-item>
        </v-tabs-items>

        <!-- Save Button -->
        <v-card-actions class="justify-end">
          <v-btn @click="save" color="green" dark>{{ $t("buttons.save") }}</v-btn>
          <v-btn @click="close" color="red" dark>{{ $t("buttons.cancel") }}</v-btn>
        </v-card-actions>

      </v-container>
    </v-card>
    <op-confirmation-dialog ref="confirm" />
  </v-dialog>
</template>
