<template>
  <div v-if="localData">
    <op-modale
      :display="show"
      :data="html"
      v-on:closed="closedPreview"
    ></op-modale>

    <v-card elevation="0" outlined color="#fafafa" class="ma-1">
      <v-card-text>
        <v-text-field
          class="required"
          :label="$t('view.learning_phishing.page_name')"
          v-model="localData.name"
        >
        </v-text-field>

        <v-text-field
          :label="$t('view.learning_phishing.url_to_server')"
          v-model="localData.url"
          class="required"
        >
        </v-text-field>
        <!-- Tag syntax help section -->
        <TagHelpCard />

        <v-textarea
          class="required"
          :label="$t('view.learning_phishing.page_html')"
          v-model="localData.html"
        >
        </v-textarea>

        <v-checkbox
          v-model="localData.capture_credentials"
          :label="$t('view.learning_phishing.capture_credentials')"
        ></v-checkbox>

        <div v-if="localData.capture_credentials">
          <v-checkbox
            v-model="localData.capture_password"
            :label="$t('view.learning_phishing.capture_password')"
          ></v-checkbox>

          <v-text-field
            :label="$t('view.learning_phishing.redirect_url_of_the_page')"
            v-model="localData.redirect_url"
          >
          </v-text-field>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg"
          >{{ $t("buttons.save") }}
        </v-btn>
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{
          $t("buttons.close")
        }}</v-btn>

        <v-btn elevation="2" @click="openPreview()" class="text-none rounded-lg">{{
          $t("buttons.preview")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { showSnackbar } from "@/services/GlobalActions"
export default {
  name: "phishing-landing-page-edition",
  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      localData: null,
      show: false,
      html: null,
      textCopied: false,
      imgCopied: false,
      htmlCopied: false,
      colorCopied: false,
    }
  },
  mounted() {
    this.localData = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    form() {
      return this.$refs.form
    },
  },
  methods: {
    async submit() {
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },
    openPreview(data) {
      this.html = this.localData.html
      this.show = true
    },
    closedPreview() {
      this.html = null
      this.show = false
    },
    // Function to copy the tag syntax to the clipboard
    copyToClipboard(text, stateProp) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this[stateProp] = true

          // Show the confirmation snackbar
          showSnackbar(this.$t("view.learning_phishing.copy_success"))

          // Reset the state after 2 seconds
          setTimeout(() => {
            this[stateProp] = false
          }, 2000)
        })
        .catch((err) => {
          console.error("Erreur lors de la copie : ", err)
        })
    },
  },
}
</script>
