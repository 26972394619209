<template>
  <div v-if="localData && emailTemplates && servers && landingPages">
    <v-card elevation="0" outlined color="#fafafa" class="ma-1">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('view.learning_phishing.exercise_title')"
              v-model="localData.title"
              class="required"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('view.learning_phishing.exercise_description')"
              v-model="localData.description"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-select
              v-model="localData.lang"
              :items="languages"
              filled
              :label="$t('view.learning_phishing.select_a_language')"
              class="required"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field :label="$t('view.learning_phishing.exercise_theme')" v-model="localData.theme">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-select
              v-model="localData.phishing_email_template_id"
              :items="emailTemplates"
              item-text="name"
              item-value="id"
              filled
              :label="$t('view.learning_phishing.email_template_selection')"
              class="required"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="localData.phishing_server_id"
              :items="servers"
              item-text="url"
              item-value="id"
              filled
              :label="$t('view.learning_phishing.gophish_server_selection')"
              class="required"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-select
              v-model="localData.phishing_landing_page_id"
              :items="landingPages"
              item-text="name"
              item-value="id"
              filled
              :label="$t('view.learning_phishing.landing_page_selection')"
              class="required"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="localData.phishing_sending_profile_id"
              :items="sendingProfiles"
              item-text="name"
              item-value="id"
              filled
              :label="$t('view.learning_phishing.sending_profile_selection')"
              class="required"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-select
              v-model="localData.thematics"
              :items="thematics"
              multiple
              item-text="slug"
              item-value="id"
              filled
              :label="$t('view.learning_phishing.themes_selection')"
            ></v-select>
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="localData.stimuli"
              :items="stimuli"
              multiple
              item-text="slug"
              item-value="id"
              filled
              :label="$t('view.learning_phishing.selection_of_stimuli')"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-slider
            v-model="localData.difficulty_level"
            :max="3"
            :min="1"
            :label="$t('view.learning_phishing.difficulty_level')"
            thumb-:label="always"
          ></v-slider>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('view.learning_phishing.illustration_height')"
              v-model="localData.illustration_url"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$t('view.learning_phishing.documentation_url')"
              v-model="localData.documentation_url"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg"
        >{{ $t("buttons.save") }}
        </v-btn
        >
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{ $t("buttons.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import ConfigLogic from "@/logics/ConfigLogic";
import PhishingMixin from "@/mixins/phishing.mixin"
import AdminLogic from "@/logics/AdminLogic"
import { cloneDeep } from "lodash"

export default {
  name: "phishing-exercise-edition",
  mixins: [PhishingMixin],
  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      localData: null,
      languages: [],
      stimuli: [],
      thematics: [],
    }
  },
  async mounted() {
    this.emailTemplates = await this.getAllEmailTemplates()
    this.servers = await this.getAllServers()
    this.landingPages = await this.getAllLandingPages()
    this.sendingProfiles = await this.getAllSendingProfiles()

    this.stimuli = await AdminLogic.getAllStimuli()
    this.thematics = await AdminLogic.getAllThematics()
    this.languages = await ConfigLogic.listLanguages()

    const data = cloneDeep(this.data)

    // convert array of objects into array of IDs
    if (data.stimuli) {
      data.stimuli = data.stimuli.map((stimulus) => stimulus.id)
    }
    if (data.thematics) {
      data.thematics = data.thematics.map((thematic) => thematic.id)
    }

    this.localData = data

    this.localData.phishing_email_template_id = this.localData
      .phishing_email_template
      ? this.localData.phishing_email_template.id
      : null

    this.localData.phishing_server_id = this.localData.phishing_server
      ? this.localData.phishing_server.id
      : null

    this.localData.phishing_landing_page_id = this.localData
      .phishing_landing_page
      ? this.localData.phishing_landing_page.id
      : null

    this.localData.phishing_sending_profile_id = this.localData
      .phishing_sending_profile
      ? this.localData.phishing_sending_profile.id
      : null
  },

  computed: {
    form() {
      return this.$refs.form
    },
  },
  methods: {
    async submit() {
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },
    updateStart(data) {
      this.localProgram.start_date = data
    },
    updateEnd(data) {
      this.localProgram.end_date = data
    },
  },
}
</script>
