<template>
  <div>
    <v-card elevation="1" outlined color="#fff" class="ma-2" v-if="inited">
      <v-card-title>
        <v-row>
          <v-col cols="9" class="align-self-center">
            {{ localCatalog.name }}
          </v-col>
          <v-col cols="3" class="d-flex justify-end align-center">
            <v-chip v-if="localCatalog.is_public" color="orange" dark>
              {{ $t("view.learning_phishing.public") }}
            </v-chip>
            <v-chip color="dark-grey" dark v-else>
              {{ $t("view.learning_phishing.private") }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-subtitle>
        <div v-if="localCatalog.nb_exercises">{{ localCatalog.nb_exercises }} {{ $t("view.learning_phishing.exercises") }}</div>
      </v-card-subtitle>

      <v-card-text>
        <!-- View Mode -->
        <div v-if="!isEditMode">
          <p>{{ localCatalog.description }}</p>
        </div>

        <!-- Edit Mode -->
        <div v-else>
          <v-text-field
            v-model="localCatalog.id"
            :label="$t('view.learning_phishing.catalog_id')"
            outlined
            readonly
            style="color: #9e9e9e;"
            class="mr-2"
          ></v-text-field>
          <v-checkbox
            v-model="localCatalog.is_public"
            :label="$t('view.learning_phishing.public')"
          ></v-checkbox>
          <v-text-field
            v-model="localCatalog.name"
            :label="$t('view.learning_phishing.catalog_name')"
            outlined
          ></v-text-field>
          <v-textarea
            v-model="localCatalog.description"
            :label="$t('view.learning_phishing.description')"
            outlined
          ></v-textarea>



          <!--v-select
            v-if="phishingExercises.length"
            v-model="selectedExercises"
            :items="phishingExercises"
            item-text="title"
            item-value="id"
            :label="$t('view.learning_phishing.select_exercises')"
            multiple
            chips
          ></v-select-->

          <h3>{{ $t("view.learning_phishing.companies_perimeters") }}</h3>
          <v-select
            v-if="perimeters.length"
            v-model="selectedPerimeters"
            :items="perimeters"
            item-text="slug"
            item-value="id"
            :label="$t('view.learning_phishing.select_perimeters')"
            multiple
            chips
          ></v-select>


          <!-- Choix des exercices de phishing -->

          <h3 class="mb-2">{{ $t("view.learning_phishing.phishing_exercises") }}</h3>
          <v-data-table
            :items="phishingExercises"
            :headers="exerciseHeaders"
            :search="exerciseSearchQuery"
            item-value="id"
            show-select
            v-model="selectedExercises"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-text-field
                v-model="exerciseSearchQuery"
                :label="$t('label.search')"
                class="mx-4"
                clearable
                dense
                outlined
              ></v-text-field>
            </template>
          </v-data-table>



          <!-- Companies Selection -->
          <h3>{{ $t("view.learning_phishing.companies_direct") }}</h3>
          <v-btn
            icon
            @click="expandCompanies = !expandCompanies"
            class="mb-2"
          >
            <v-icon>{{ expandCompanies ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-expand-transition>
            <div v-if="expandCompanies">
              <v-text-field
                v-model="searchQuery"
                :label="$t('view.learning_phishing.search_for_a_company')"
                outlined
              ></v-text-field>
              <div class="company-list">
                <div
                  v-for="company in filteredCompanies"
                  :key="company.id"
                  class="company-item"
                  :class="companyClass(company)"
                >
                  <span class="company-name mr-2">{{ company.business_name }}</span>
                  <v-btn @click="toggleCompanyAccess(company)" small>
                    {{ isCompanyAuthorized(company) ? "-" : "+" }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-expand-transition>

        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-icon
          @click="suppress()"
          v-if="!isEditMode"
          class="ml-2"
          elevation="2"
          icon
        >mdi-delete
        </v-icon>
        <v-btn
          v-if="!isEditMode"
          small
          @click="enterEditMode"
          class="ml-2 text-none"
        >{{ $t("view.custom_editor.modify") }}
        </v-btn>
        <v-btn v-if="isEditMode" @click="save" dark color="green"  small class="text-none"
        >{{ $t("buttons.save") }}
        </v-btn>
        <v-btn v-if="isEditMode" @click="cancel" dark color="red"  small class="text-none"
        >{{ $t("buttons.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import catalogMixin from "@/mixins/phishingExerciseCatalogs.mixin"
import PhishingExerciseCatalogsLogic from "@/logics/PhishingExerciseCatalogsLogic"
import { cloneDeep } from "lodash"

export default {
  name: "PhishingExerciseCatalog",
  mixins: [catalogMixin],
  props: {
    catalog: {
      type: Object,
      required: true,
    },
    phishingExercises: {
      required: true,
      default: null,
    },
    companies: {
      required: true,
      default: null,
    },
    perimeters: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      isEditMode: false,
      localCatalog: {},
      selectedPerimeters: [],
      inited: false,
      searchQuery: "",
      fullCatalogLoaded: false,
      expandCompanies: false,
      exerciseSearchQuery: "",
      selectedExercises: [], // IDs des exercices sélectionnés
      exerciseHeaders: [
        { text: "ID", value: "id" },
        { text: this.$t("label.title"), value: "title" },
      ],
    }
  },
  computed: {
    filteredCompanies() {
      return this.companies.filter((company) =>
        company.business_name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      )
    },
  },
  mounted() {
    this.initComponent()
  },
  methods: {
    async initComponent() {
      this.localCatalog = {...this.catalog}
      this.inited = true
    },
    async enterEditMode() {
     if(!this.fullCatalogLoaded){
       // Ici on va requêter plus d'information sur le catalog pour avoir les companies et les perimeters
       this.localCatalog = await PhishingExerciseCatalogsLogic.getSingleCatalog(this.localCatalog.id);

       // On initialise les périmètres
       this.selectedPerimeters = this.localCatalog.perimeters.map((e) => e.id)

      // On initialise les exercices
       /*this.selectedExercises = this.localCatalog.phishing_exercises.map(
         (e) => e.id
       )*/
       this.selectedExercises = cloneDeep(this.localCatalog.phishing_exercises);

       console.log("selected exercises",  this.selectedExercises)
     }else {
       // Initialiser les exercices déjà chargés si les détails sont déjà disponibles
       this.selectedExercises = this.localCatalog.phishing_exercises.map((e) => e.id);
     }

      this.isEditMode = true
    },
    async save() {
      const payload = {
        id : this.localCatalog.id ?? null,
        description: this.localCatalog.description,
        name: this.localCatalog.name,
        is_public: this.localCatalog.is_public,
        //exercise_ids: this.selectedExercises,
        exercise_ids: this.selectedExercises.map((exercise) => exercise.id),
        perimeters_ids: this.selectedPerimeters,
        company_uuids: this.localCatalog.companies.map((c) => c.uuid),
      }

      console.log("payload", payload)

      try {
        await this.updateCatalog(payload.id, payload) // Assuming you have an updateCatalog method that handles the API call
        this.isEditMode = false
        this.$emit("update")
      } catch (error) {
        console.error("An error occurred while updating the catalog:", error)
      }
    },
    async suppress() {
      await this.deleteCatalog(this.localCatalog.id)
      this.$emit("deleted")
    },
    isCompanyAuthorized(company) {
      return !!this.localCatalog.companies.find(
        (c) => c.uuid === company.uuid
      )
    },
    toggleCompanyAccess(company) {
      const index = this.localCatalog.companies.findIndex(
        (c) => c.uuid === company.uuid
      )
      if (index > -1) {
        // retourne nouveau tableau pour forcer la réactivité de la filtered list
        this.localCatalog.companies = [
          ...this.localCatalog.companies.slice(0, index),
          ...this.localCatalog.companies.slice(index + 1),
        ]
      } else {
        this.localCatalog.companies = [...this.localCatalog.companies, company]
      }
    },
    cancel() {
      this.isEditMode = false
    },
    companyClass(company) {
      return this.isCompanyAuthorized(company) ? "authorized" : ""
    },
  },
}
</script>

<style scoped>
.company-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.company-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.authorized {
  background-color: #e0e0e0;
}

.company-name {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
