<script>
import AuthStore from "@/stores/AuthStore"
import { i18n } from "@/plugins/i18n"
import { showSnackbar } from "@/services/GlobalActions"
import utilsMixin from "@/mixins/utils.mixin"
import usersMixin from "@/mixins/users.mixins"

export default {
  name: "company-general-edition",
  mixins: [utilsMixin, usersMixin],
  props: {
    data: {
      default: null,
    },
    is_learning_sso_enable: {
      default: null,
    },
  },
  data: function() {
    return {
      localData: null,
      companyAdminPerimeters: [],
      isCompanyAdmin: false,
      isSystemAdmin: false,
    }
  },
  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin")

    if (!this.data.uuid) {
      // the perimeters are only editable in creation mode
      let user = AuthStore.me
      this.companyAdminPerimeters  = await this.getUserPerimeters(
        user,
        "company-admin",
      )
    }

    this.localData = JSON.parse(JSON.stringify(this.data))

    if (
      !this.localData.perimeter_id &&
      this.companyAdminPerimeters.length === 1
    ) {
      this.localData.perimeter_id = this.companyAdminPerimeters[0].id
    }
  },

  watch: {
    data(newValue, oldValue) {
      this.localData = JSON.parse(JSON.stringify(newValue))
    },
    is_learning_sso_enable(newValue) {
      if (!newValue && this.localData.employees_auth_method === "sso") {
        this.localData.employees_auth_method = "magic_link"
      }
    },
  },
  computed: {
    isMagicLink: {
      get() {
        return this.localData.employees_auth_method === "magic_link"
      },
      set(value) {
        if (value) this.localData.employees_auth_method = "magic_link"
        if (!value && !this.isEmailPwd && !this.isSSOAzure) {
          this.localData.employees_auth_method = "email_pwd"
        }
      },
    },
    isEmailPwd: {
      get() {
        return this.localData.employees_auth_method === "email_pwd"
      },
      set(value) {
        if (value) this.localData.employees_auth_method = "email_pwd"
        if (!value && !this.isMagicLink && !this.isSSOAzure) {
          this.localData.employees_auth_method = "magic_link"
        }
      },
    },
    isSSOAzure: {
      get() {
        return this.localData.employees_auth_method === "sso"
      },
      set(value) {
        if (value) this.localData.employees_auth_method = "sso"
        if (
          (!value && !this.isMagicLink && !this.isEmailPwd) ||
          !this.is_learning_sso_enable
        ) {
          this.localData.employees_auth_method = "magic_link"
        }
      },
    },
    isSaas: {
      get() {
        return this.localData.is_saas
      },
      set(value) {
        this.localData.is_saas = value
      },
    },
  },
  methods: {
    async validate() {
      // Perform validation logic - Return true if validation passes, false otherwise
      if (this.localData.perimeter_id === null) {
        showSnackbar(i18n.t("view.companies.please_select_perimeters"))
        return false
      }

      return true
    },
    getCompanyData() {
      // Return the company data specific to this component

      const data = {
        business_name: this.localData.business_name,
        trade_name: this.localData.trade_name,
        siren_number: this.localData.siren_number,
        tva_number: this.localData.tva_number,
        phone_number: this.localData.phone_number,
        email_address: this.localData.email_address,
        website: this.localData.website,
        perimeter_id: this.localData.perimeter_id,
        employees_auth_method: this.localData.employees_auth_method,
        is_saas: this.localData.is_saas,
      }

      return data
    },
  },
}
</script>

<template>
  <div class="flex-container" v-if="localData">
    <!-- Identity -->
    <div class="bloc">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("profile.identity") | titlecase }}
          </div>
        </v-col>

        <v-col cols="12">
          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="business_name"
              type="text"
              rules=""
              color="green"
              v-model="localData.business_name"
              :label="$t('profile.business_name')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="trade_name"
              type="text"
              rules=""
              color="green"
              v-model="localData.trade_name"
              :label="$t('profile.trade_name')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="siren_number"
              type="text"
              :rules="'min:9|max:9'"
              color="green"
              v-model="localData.siren_number"
              :label="$t('profile.siren_number')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="tva_number"
              type="text"
              rules=""
              color="green"
              v-model="localData.tva_number"
              :label="$t('profile.tva_number')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0" v-if="isSystemAdmin">
            <v-switch
              v-model="isSaas"
              flat
              :label="$t('profile.saas_client')"
              color="green"
            ></v-switch>
          </v-card-text>
        </v-col>
      </v-row>
    </div>

    <!-- Address -->
    <div class="bloc">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("profile.contact") | titlecase }}
          </div>
        </v-col>

        <v-col cols="12">
          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="phone_number"
              color="green"
              v-model="localData.phone_number"
              :label="$t('profile.phone_number')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="email_address"
              rules="email"
              color="green"
              v-model="localData.email_address"
              :label="$t('profile.company_email_address')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="website"
              type="text"
              color="green"
              v-model="localData.website"
              :label="$t('profile.website')"
            >
            </op-text-field>
          </v-card-text>
        </v-col>
      </v-row>
    </div>

    <!-- Choix du type -->
    <div class="bloc">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("profile.oppens_learning_training_settings") | titlecase }}
          </div>
        </v-col>
        <v-col cols="12">
          {{ $t("profile.authentication_method") }}

          <v-switch
            v-model="isMagicLink"
            flat
            :label="$t('profile.magic_link')"
            color="green"
          ></v-switch>

          <v-switch
            v-model="isEmailPwd"
            flat
            :label="$t('profile.email_pwd')"
            color="green"
          ></v-switch>
          <div class="sso-switch-container">
            <v-switch
              v-model="isSSOAzure"
              :disabled="!is_learning_sso_enable"
              flat
              :label="$t('Authentication SSO')"
              color="green"
            ></v-switch>
            <v-icon
              v-if="!is_learning_sso_enable"
              color="warning"
              class="warning-icon"
            >mdi-alert
            </v-icon
            >
            <span v-if="!is_learning_sso_enable" class="warning-message">{{
                $t("profile.active_directory_credentials.sso_warning_message")
              }}</span>
          </div>
        </v-col>
      </v-row>
    </div>

    <!--Choix du périmètre (uniquement en mode création)-->
    <div class="bloc" v-if="!localData.uuid">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("profile.choice_of_perimeters") | titlecase }}
          </div>

          <div class="mt-5">
            <v-select
              v-model="localData.perimeter_id"
              :items="companyAdminPerimeters"
              item-text="name"
              item-value="id"
              :label="$t('profile.perimeter')"
              class="required"
              outlined
              dense
              required
              clearable
              :disabled="companyAdminPerimeters.length <= 0"
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.bloc {
  border: 1px solid #efefef;
  border-radius: 5px;
  flex: 1 1 auto;
  width: 250px;
  padding: 20px;
  margin: 11px;
}

.bloc-title {
  font-weight: bold;
  text-align: center;
  font-size: 1.5em;
  color: #2a2929;
}

.sso-switch-container {
  display: flex;
  align-items: center;
  position: relative;
}

.warning-icon {
  margin-left: 10px;
  cursor: pointer;
}

.warning-message {
  display: none;
  position: absolute;
  top: 0;
  left: 75%;
  background-color: #ff9800;
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
  font-size: 12px;
  width: 150px;
  white-space: normal;
}

.warning-icon:hover + .warning-message {
  display: block;
}
</style>
