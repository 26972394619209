<template>
  <div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>

    <v-card v-if="data && data.hat_course" elevation="0" outlined>
      <v-expansion-panels v-model="openPanel">
        <v-expansion-panel>
          <v-expansion-panel-header class="oppens-pannel-header">
            <v-row>
              <v-col
                cols="5"
                class="text-capitalize d-flex align-center justify-start"
              >
                <div class="d-flex flex-column">
                  <div>
                    <!-- icon and title -->
                    <v-icon class="mr-1" color="#000">mdi-school</v-icon>
                    <span class="mb-0">
                      {{ data.name || data.hat_course.title }}
                    </span>
                  </div>
                  <div v-if="data.start_date" class="pl-5">
                    <span class="small-subtitle">
                      {{ formatDate(data.start_date) }}
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="3" class="d-flex align-center">
                <template v-if="shouldDisplay">
                  <img
                    src="@/assets/completed.svg"
                    class="me-2 custom-small-img"
                  />
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span
                        v-on="on"
                        style="text-transform: lowercase"
                        class="me-8 percantage"
                      >
                        {{ participationRate }}%
                      </span>
                    </template>
                    <span>{{ $t("view.companies.participation_rate") }}</span>
                  </v-tooltip>
                  <img
                    src="@/assets/success.svg"
                    class="me-2 custom-small-img"
                  />
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span
                        v-on="on"
                        style="text-transform: lowercase"
                        class="percantage"
                      >
                        {{ successRate }}%
                      </span>
                    </template>
                    <span>{{ $t("view.companies.success_rate") }}</span>
                  </v-tooltip>
                </template>
              </v-col>

              <v-col cols="4" class="d-flex justify-end flex-column align-end">
                <div class="d-flex justify-end align-end">
                  <v-icon
                    v-if="data.has_automatic_reminders"
                    class="mr-2"
                    elevation="2"
                    icon
                    >mdi-email-check
                  </v-icon>

                  <v-icon
                    v-if="data.has_automatic_launch"
                    class="mr-2"
                    elevation="2"
                    icon
                    >mdi-timelapse
                  </v-icon>

                  <v-chip
                    small
                    v-if="data.is_active"
                    dark
                    color="#1ac8aa"
                    class="mr-1"
                    >{{ $t("view.companies.active") }}
                  </v-chip>
                  <v-chip
                    small
                    v-if="data.is_stopped"
                    dark
                    color="red"
                    class="mr-1"
                    >{{ $t("view.companies.stopped") }}
                  </v-chip>

                  <v-chip
                    small
                    v-if="data.is_live"
                    dark
                    color="#4aa2da"
                    class="mr-1"
                    >{{ $t("view.companies.is_live") }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content
            class="panel-content-border oppens-pannel-content"
          >
            <v-row>
              <v-col cols="6">
                <h4 class="mb-2">{{ $t("view.companies.informations") }} :</h4>

                <div>
                  <span class="subtitle"
                    >{{ $t("view.learning_phishing.hat_courses") }} :</span
                  >
                  ({{ data.hat_course.id }}) - {{ data.hat_course.title }}
                </div>

                <div>
                  <span class="subtitle"
                    >{{ $t("view.learning_phishing.description") }} :</span
                  >
                  {{ data.description || data.hat_course.description }}
                </div>

                <div>
                  <span class="subtitle"
                    >{{ $t("view.companies.mandatory") }} :</span
                  >
                  {{ data.is_mandatory ? $t("YES") : $t("NO") }}
                </div>

                <div>
                  <span class="subtitle"
                    >{{ $t("view.companies.employees_group") }} :</span
                  >
                  {{
                    data.employee_groups.map((group) => group.name).join(" | ")
                  }}
                </div>

                <div>
                  <span class="subtitle"
                  >{{ $t("view.dashboard.internal_references") }} :</span
                  >
                   <span v-if="data.id" class="pl-1">{{ data.id }}</span>
                  <span v-if="data.oppens_learning_company_course_id"> . {{ data.oppens_learning_company_course_id }}</span>

                </div>

                <div v-if="data.start_date">
                  <span class="subtitle"
                    >{{ $t("view.dashboard.start_date") }} :</span
                  >
                  {{ formatDate(data.start_date) }}
                </div>

                <v-chip small v-if="data.are_invitations_sent" dark color="blue"
                  >{{ $t("view.dashboard.invitation_issued") }}
                </v-chip>
              </v-col>

              <v-col cols="3">
                <h4 class="mb-2">{{ $t("view.companies.automation") }} :</h4>

                <op-automation-component :entity="data">
                </op-automation-component>
              </v-col>
            </v-row>

            <v-card-actions class="d-flex justify-end" >
              <div style="background-color: white">
                <!--v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="green"
                      class="ma-2"
                      @click="toggleEmployees()"
                      >mdi-account-group
                    </v-icon>
                  </template>
                  <span>{{ $t("view.dashboard.employees") }}</span>
                </v-tooltip-->

                <v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="green"
                      class="ma-2"
                      @click="edit()"
                      :disabled="data.is_stopped"
                      >mdi-pencil
                    </v-icon>
                  </template>
                  <span>{{ $t("buttons.edit") }}</span>
                </v-tooltip>

                <v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="green"
                      @click="toggleResults()"
                      class="ma-2"
                      >mdi-trophy-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("view.companies.results") }}</span>
                </v-tooltip>

                <v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="green"
                      :disabled="!data.is_active"
                      class="ma-2"
                      @click="invite()"
                      >mdi-email-check-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("view.dashboard.invited") }}</span>
                </v-tooltip>

                <v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="green"
                      :disabled="!data.is_active"
                      class="ma-2"
                      @click="remind()"
                    >
                      mdi-email-multiple-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("view.companies.reminder") }}</span>
                </v-tooltip>

                <v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="start()"
                      color="green"
                      :disabled="data.start_date != null"
                      class="ma-2"
                    >
                      mdi-play-circle-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("buttons.launch") }}</span>
                </v-tooltip>

                <v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="red"
                      @click="stop()"
                      :disabled="!data.is_active"
                      class="ma-2"
                      >mdi-stop-circle-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("view.companies.stop") }}</span>
                </v-tooltip>

                <v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="red"
                      @click="suppress()"
                      class="ma-2"
                      elevation="2"
                      icon
                      >mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $t("buttons.delete") }}</span>
                </v-tooltip>
              </div>
            </v-card-actions>

            <v-expand-transition>
              <div>
                <!-- Display employees in a data table if revealed -->
                <div v-if="employees && revealEmployees">
                  <v-data-table
                    :multi-sort="true"
                    :items="employees"
                    :server-items-length="totalEmployees"
                    @update:options="changeListOptions"
                    :page.sync="currentPage"
                    :headers="shortHeadersv2"
                    :footer-props="{
                      'items-per-page-options': itemsPerPageOptions,
                    }"
                    :items-per-page="itemsPerPage"
                    :loading="employeeTableIsLoading"
                    class="elevation-1"
                  >
                  </v-data-table>
                </div>

                <!-- Display results in a dedicated component -->
                <div v-if="revealResults">
                  <oppens-learning-course-results
                    v-on:export="exportResults"
                    v-on:export-sessions="exportCompletedSessions"
                    :results="results"
                  >
                  </oppens-learning-course-results>
                </div>
              </div>
            </v-expand-transition>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import learningMixin from "@/mixins/learning.mixin"
import employeeTableMixin from "@/mixins/employeeTable.mixin"
import moment from "moment"
import LearningLogic from "@/logics/LearningLogic"
import { i18n } from "@/plugins/i18n"
import { saveAs } from "file-saver"
import { showSnackbar, unAuthorized } from "@/services/GlobalActions"

export default {
  name: "learning-company-course",
  mixins: [learningMixin, employeeTableMixin],
  props: {
    modeLine: null,
    data: {
      default: null,
    },
  },
  data: () => {
    return {
      openPanel: null,
      snackbar: false,
      revealEmployees: false,
      revealResults: false,
      search: "",
      summary: {},
      results: null,
      employees: null,
    }
  },

  created() {
    this.openPanel = this.modeLine ? null : 0
    if (this.data && this.data.stats && this.data.stats.summary) {
      this.summary = this.data.stats.summary
    }
  },

  mounted() {},
  watch: {
    modeLine(newValue) {
      this.openPanel = newValue ? null : 0
    },
  },

  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
    shouldDisplay() {
      return (
        this.summary.nbEmployeesInvited && this.summary.nbEmployeesInvited > 0
      )
    },
    participationRate() {
      if (
        !this.summary.nbEmployeesInvited ||
        this.summary.nbEmployeesInvited === 0
      ) {
        return 0
      }
      return Math.round(
        (this.summary.nbEmployeesCompleted / this.summary.nbEmployeesInvited) *
          100
      )
    },
    successRate() {
      if (
        !this.summary.nbEmployeesCompleted ||
        this.summary.nbEmployeesCompleted === 0
      ) {
        return 0
      }
      return Math.round(
        (this.summary.nbEmployeesSuccess / this.summary.nbEmployeesCompleted) *
          100
      )
    },
  },

  methods: {
    formatDate(str) {
      return moment(str).format("MMMM D, YYYY")
    },

    async suppress() {
      this.$emit("suppress", this.data)
    },

    async stop() {
      this.$emit("stop", this.data)
    },

    edit() {
      this.$emit("edit", this.data)
    },

    invite() {
      this.$emit("invite", this.data)
    },

    remind() {
      this.$emit("remind", this.data)
    },

    start() {
      this.$emit("start", this.data)
    },

    /*
    async toggleEmployees() {
      const revealEmployees = !this.revealEmployees

      if (revealEmployees && (!this.employees || this.employees.length === 0)) {
        await this.refreshEmployees(1)
      }

      if (revealEmployees) {
        this.revealResults = false
      }

      this.revealEmployees = revealEmployees
    },*/

    async toggleResults() {
      const revealResults = !this.revealResults

      if (revealResults) {
        await this.getResults()
        this.makeSurePanelIsOpen()
        this.revealEmployees = false
      }

      this.revealResults = revealResults
    },

    makeSurePanelIsOpen() {
      if (null === this.openPanel) {
        this.openPanel = 0
      }
    },

    async refreshEmployees(pageNumber) {
      if (!this.data.id || !this.companyUuid || this.employeeTableIsLoading) {
        return
      }

      try {
        this.employeeTableIsLoading = true

        let params = this.getParams(pageNumber)
        if (pageNumber) {
          this.currentPage = pageNumber
        }

        const result = await LearningLogic.getEmployees(this.companyUuid, {
          ...params,
          ...this.sortOptions,
          "company_courses_ids[]": this.data.id,
          with_results: 0,
          with_summary: 0,
        })

        this.employees = result.data.employees
        this.totalEmployees = result.meta.total
        this.filteringOptions = result.meta.filtering_options
      } catch (error) {
        //this.displayAlert(error?.response?.data?.error?.message)
        console.error(error?.response?.data?.error?.message)
      } finally {
        this.employeeTableIsLoading = false
      }
    },

    async getResults() {
      this.results = []

      const courseResults = await this.getCompanyCourseResults(this.data.id)

      if (!courseResults) {
        this.results = null
        showSnackbar(i18n.t("No results yet"))
        return
      }

      Object.values(courseResults).forEach((val, index) => {
        let result = {
          first_name: null,
          last_name: null,
          email: null,
          score: null,
        }

        if (val.employee) {
          result.first_name = val.employee.first_name
          result.last_name = val.employee.last_name
          result.email = val.employee.email
        }
        if (val.result) {
          result.score = val.result.score
          result.session_stopped_at = val.result.session_stopped_at
            ? this.formatDate(val.result.session_stopped_at)
            : null
        }

        this.results.push(result)
      })
    },

    async export(exportType) {
      let res = await LearningLogic.export(
        this.companyUuid,
        this.data.id,
        exportType
      )

      let fileName = `${this.data.name}-${exportType}.csv`

      saveAs(res.data, fileName)
    },
    async exportCompletedSessions() {
      await this.export("completed-sessions")
    },
    async exportResults() {
      await this.export("results")
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-btn {
  padding: 28px 10px 30px 10px;
}

.invite-btn {
  padding: 28px 10px 30px 10px;
}

.subtitle {
  min-width: 250px;
  display: inline-block;
  color: #7a7a7a;
  font-size: 0.9rem
}

.date {
  font-size: 14px;
  margin-left: 5px;
  min-width: 150px;
  margin: 0;
}

.users {
  border-left: 1px dashed #afa8a8;
  padding-left: 10px;
  display: inline-block;
  width: 100%;
  //padding: 20px;

  ._title {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
    font-style: oblique;
  }

  .user {
    //border: 1px solid #dadae6;
    display: block;
    margin-bottom: 5px;

    .email {
      margin: 5px;
      float: left;
      min-width: 250px;
    }
  }
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.small-subtitle {
  font-size: 0.9rem;
  text-transform: capitalize;
  color: grey;
  padding-left:10px
}
.custom-small-img {
  width: 2rem;
  height: 2rem;
}

.percantage {
  width: 50px;
}
</style>
